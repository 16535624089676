import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { server, config } from "./env";
import ErrorBoundary from "./components/ErrorBoundary";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./source/Home";
import Feedback from "./source/Feedback";
import FindOrder from "./source/FindOrder";
import Shop from "./source/Shop";
import Redeem from "./source/Redeem";
import FAQ from "./source/FAQ";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import Forgot from "./auth/Forgot";
import Reset from "./auth/Reset";
import Ticket from "./source/Ticket";
import Balance from "./source/Balance";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsOfService from "./source/ToS";
import PrivacyPolicy from "./source/PrivacyPolicy";
import Settings from "./source/Settings";
import Dashboard from "./source/Dashboard";

function AppContent() {
  const headerRef = React.createRef();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const navigate = useNavigate();

  const checkLogin = React.useCallback(async () => {
    try {
      const response = await axios.get(server + "/v1/user/access", config);
      setIsLoggedIn(true);
      localStorage.setItem("email", response.data.payload.email);
      
      // Redirect to dashboard if on auth pages or home page
      const protectedPaths = ['/', '/login', '/signup', '/reset', '/forgot'];
      if (protectedPaths.includes(window.location.pathname)) {
        navigate('/dashboard', { replace: true });
      }
    } catch (err) {
      localStorage.removeItem("email");
      setIsLoggedIn(false);
    }
  }, [navigate]);

  useEffect(() => {
    checkLogin();
    
    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('popstate', handleLocationChange);
    window.addEventListener('pushstate', handleLocationChange);
    window.addEventListener('replacestate', handleLocationChange);

    const observer = new MutationObserver(() => {
      setCurrentPath(window.location.pathname);
    });
    observer.observe(document.querySelector('#root'), { subtree: true, childList: true });

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.removeEventListener('pushstate', handleLocationChange);
      window.removeEventListener('replacestate', handleLocationChange);
      observer.disconnect();
    };
  }, [checkLogin]);

  const refreshCart = async () => {
    headerRef.current?.refreshCart();
  };

  const shouldShowHeader = currentPath !== "/" && 
                          currentPath !== "/terms-of-service" && 
                          currentPath !== "/privacy-policy";
                          
  const shouldShowFooter = shouldShowHeader;

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {shouldShowHeader && <Header loggedIn={isLoggedIn} ref={headerRef} />}
      <main style={{ flex: 1 }}>
        <Routes>
          <Route path="/redeem-key" element={<Redeem />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="/shop" element={
            <ErrorBoundary fallback={<div className="container py-5 text-center">
              <h2>Something went wrong loading the shop.</h2>
              <button className="btn btn-primary mt-3" onClick={() => window.location.reload()}>
                Refresh Page
              </button>
            </div>}>
              <Shop refreshCart={refreshCart} key={`shop-${currentPath}`} />
            </ErrorBoundary>
          } />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/find-order" element={<FindOrder />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          {/* Auth Routes */}
          <Route
            path="/login"
            element={!isLoggedIn ? <Login /> : <Navigate replace to="/dashboard" />}
          />
          <Route
            path="/reset"
            element={!isLoggedIn ? <Reset /> : <Navigate replace to="/dashboard" />}
          />
          <Route
            path="/forgot"
            element={!isLoggedIn ? <Forgot /> : <Navigate replace to="/dashboard" />}
          />
          <Route
            path="/signup"
            element={!isLoggedIn ? <Signup /> : <Navigate replace to="/dashboard" />}
          />

          {/* Authenticated Routes */}
          <Route
            path="/dashboard"
            element={isLoggedIn ? <Dashboard /> : <Navigate replace to="/login" />}
          />
          <Route
            path="/settings"
            element={isLoggedIn ? <Settings /> : <Navigate replace to="/login" />}
          />
          <Route
            path="/tickets"
            element={isLoggedIn ? <Ticket /> : <Navigate replace to="/login" />}
          />
          <Route
            path="/balance"
            element={isLoggedIn ? <Balance /> : <Navigate replace to="/login" />}
          />

          {/* Home Route with Conditional Redirect */}
          <Route 
            path="/*" 
            element={isLoggedIn ? <Navigate replace to="/dashboard" /> : <Home />} 
          />
        </Routes>
      </main>
      {shouldShowFooter && <Footer loggedIn={isLoggedIn} />}
      <ToastContainer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
