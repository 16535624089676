import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link } from "react-router-dom";
import Requests from "../utils/requests";

const requests = new Requests();

// Prefetch function to load shop data in the background
const prefetchShopData = () => {
  try {
    // Start fetching in the background with proper error handling
    // We'll use separate promises with .catch to handle errors individually
    const fetchProducts = requests.getProducts().catch(err => {
      // Silently handle unauthorized errors - will be handled on the Shop page
      if (err?.response?.status === 401) {
        console.log("Unauthorized when prefetching products, will handle on Shop page");
        return [];
      }
      console.error("Error prefetching products:", err);
      return [];
    });
    
    const fetchCategories = requests.getCategories().catch(err => {
      // Silently handle unauthorized errors - will be handled on the Shop page
      if (err?.response?.status === 401) {
        console.log("Unauthorized when prefetching categories, will handle on Shop page");
        return [];
      }
      console.error("Error prefetching categories:", err);
      return [];
    });
    
    // Execute both promises in parallel
    Promise.all([fetchProducts, fetchCategories]);
    // We don't need to use the result, just trigger the fetch
  } catch (e) {
    console.error("Error in prefetchShopData:", e);
  }
};

// Improved debounce function for wheel events
const debounce = (func, wait) => {
  let timeout;
  
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Add sample FAQs data
const SampleFAQs = [
  {
    id: 1,
    question: "How do I purchase a subscription?",
    answer: "Click the Store button found at the top of our website, search for any product you want to purchase, select your desired warranty, payment method, and complete the payment."
  },
  {
    id: 2,
    question: "My subscription stopped working. What do I do?",
    answer: "As an unregistered member you can request a replacement from the Find Order section of our website. If you have an account with us, you can log in to your dashboard and manage all your orders from there."
  },
  {
    id: 3,
    question: "How long is my warranty and how does it work?",
    answer: "When you purchase a product, there are options for how long you want the warranty to last. This will grant you access to a \"replacement!\" Button if you ever need it."
  },
  {
    id: 4,
    question: "When will I receive my product?",
    answer: "You will receive your product as soon as the payment is complete."
  },
  {
    id: 5,
    question: "I didn't get an email after purchase, what to do?",
    answer: "Check spam for an email from limitless.store, our emals have ended up in spam before. If any of our emails don't get delivered to both spam and inbox just contact us and we will resolve it."
  }
];

// Payment methods displayed in the Payment section
const PaymentMethods = [
  { name: "Bitcoin", icon: "fab fa-bitcoin" },
  { name: "Ethereum", icon: "fab fa-ethereum" },
  { name: "Litecoin", icon: "custom-icon litecoin-icon" },
  { name: "PayPal", icon: "fab fa-paypal" }
];

// Features displayed in the Features section
const Features = [
  {
    icon: "fa-bolt",
    title: "Insanely Quick Auto-Warranty",
    description: "Goodbye waiting times and wasted energy on mediocre support. Need a fix? Sure! Just click 1 button!"
  },
  {
    icon: "fa-shield-halved",
    title: "Highly Secure Transactions",
    description: "We ensure full security and anonymity of all placed orders. We use the most secure payment methods available."
  },
  {
    icon: "fa-tag",
    title: "Best Prices on all Products",
    description: "Our prices are unbeatable. We offer the best value for your money, guaranteed. Compare and see for yourself!"
  },
  {
    icon: "fa-headset",
    title: "Industry Leading Support",
    description: "Our support team is available 24/7 to assist you with any questions or issues. We're here to help!"
  }
];

// Stats displayed in the Hero section
const Stats = [
  { number: "35,000+", label: "ORDERS" },
  { number: "1,500+", label: "REVIEWS" },
  { number: "300+", label: "PRODUCTS" }
];

// Add custom CSS for Litecoin icon
const litecoinSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
  <path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M11,7H13V14.4L15.4,14L15,16L12.7,16.4L11,16.7V7Z" />
</svg>`;

// Updated icons object with image paths instead of SVG components
const streamingIcons = {
  netflix: "/assets/images/Netflix_App_icon.png",
  disney: "/assets/images/Disney__App_icon.png",
  youtube: "/assets/images/YouTube_for_Android_TV_App_icon.png",
  spotify: "/assets/images/Spotify_Lite_App_icon.png",
  hbo: "/assets/images/HBO_Max__Stream_TV___Movies_App_icon.png",
  apple: "/assets/images/Apple_TV__Android_TV__App_icon.png",
  paramount: "/assets/images/Paramount__App_icon.png",
  steam: "/assets/images/Steam_App_icon.png",
  xbox: "/assets/images/Xbox_Game_Pass_App_icon.png",
  chatgpt: "/assets/images/ChatGPT_App_icon.png",
  adobe: "/assets/images/Adobe_After_Effects_App_icon.png",
  applemusic: "/assets/images/Apple_Music_App_icon.png",
  crunchyroll: "/assets/images/Crunchyroll_Manga_App_icon.png"
};

// Function to get a color based on icon type (keeping for compatibility)
const getIconColor = (iconType) => {
  const colorMap = {
    netflix: "#E50914",
    disney: "#0063e5",
    youtube: "#FF0000",
    spotify: "#1DB954",
    hbo: "#5822b4",
    apple: "#A3AAAE",
    paramount: "#0064FF",
    steam: "#1b2838",
    xbox: "#107C10",
    chatgpt: "#74aa9c",
    adobe: "#FF3366",
    applemusic: "#FB233B",
    crunchyroll: "#F47521"
  };

  return colorMap[iconType] || "#ffffff";
};

// Component for the mouse trail and burst effect
const MouseTrailEffect = () => {
  const [particles, setParticles] = useState([]);
  const requestRef = useRef(null);
  const lastPosition = useRef({ x: 0, y: 0 });
  const trailThrottleRef = useRef(0);
  // Add a ref to track which icons are currently in use
  const activeIconsRef = useRef(new Set());
  // Add a ref to store interactive DOM elements
  const interactiveElementsRef = useRef([]);
  // Ref to track if we should refresh our element cache
  const shouldRefreshElements = useRef(true);
  // Ref to detect device performance
  const isLowPerformanceDevice = useRef(false);
  // Ref to track consecutive clicks
  const clickCountRef = useRef(0);
  const lastClickTimeRef = useRef(0);

  // Check for low performance device on component mount
  useEffect(() => {
    // Simple heuristic to identify lower-powered devices
    const ua = navigator.userAgent;
    // iPhone performance check - phones likely need optimizations
    if (/iPhone|iPad|iPod|Android/.test(ua)) {
      isLowPerformanceDevice.current = true;
    }

    // Also check for older devices or browsers with limited capabilities
    if (
      !window.requestAnimationFrame ||
      !document.createElement('canvas').getContext ||
      (window.performance && window.performance.now && window.performance.now() > 120) // Initial page load perf check
    ) {
      isLowPerformanceDevice.current = true;
    }
  }, []);

  // Get random icon from streamingIcons object that's not currently in use
  const getRandomIcon = () => {
    const keys = Object.keys(streamingIcons);
    const availableKeys = keys.filter(key => !activeIconsRef.current.has(key));
    
    // If all icons are in use, allow repeats but try to pick least recently used
    const keyToUse = availableKeys.length > 0 
      ? availableKeys[Math.floor(Math.random() * availableKeys.length)]
      : keys[Math.floor(Math.random() * keys.length)];
    
    // Add to active icons
    activeIconsRef.current.add(keyToUse);
    
    return {
      type: keyToUse,
      src: streamingIcons[keyToUse]
    };
  };

  // Create a trail particle at specified position
  const createTrailParticle = useCallback((x, y, isClick = false) => {
    const { type, src } = getRandomIcon();
    // Adjust sizes for low-performance devices
    const sizeFactor = isLowPerformanceDevice.current ? 0.8 : 1;
    const size = (isClick ? 31 : 21) * sizeFactor;
    const sizeVariation = (isClick ? 5 : 3) * sizeFactor;
    
    // Smaller position offset for low-performance devices
    const offsetFactor = isLowPerformanceDevice.current ? 0.7 : 1;
    const randomOffsetX = (Math.random() - 0.5) * 70 * offsetFactor;
    const randomOffsetY = (Math.random() - 0.5) * 70 * offsetFactor;
    
    // Adjusted velocity for more efficient animations on low-performance devices
    const speedFactor = isClick ? 
      (isLowPerformanceDevice.current ? 4.5 : 5.9) : 
      (isLowPerformanceDevice.current ? 1.0 : 1.2);
    
    const vx = ((Math.random() - 0.5) * 2.5) * speedFactor;
    const vy = ((Math.random() * 1.0) - 1.4) * speedFactor;
    
    // Random rotation
    const rotation = Math.random() * 360;
    const rotationSpeed = (Math.random() - 0.5) * (isClick ? 
      (isLowPerformanceDevice.current ? 4 : 6) : 
      (isLowPerformanceDevice.current ? 2 : 4));
    
    // Reduce z-layer complexity for low-performance devices
    const zLayerDistribution = isLowPerformanceDevice.current ? 
      [0, 1, 1, 2, 2] : // Simplified for low-performance devices
      [0, 1, 1, 2, 2, 2, 3, 3, 4]; // Original distribution
    
    const zLayer = zLayerDistribution[Math.floor(Math.random() * zLayerDistribution.length)];
    
    // Reduce collision probability for low-performance devices
    const canCollideWithElements = isLowPerformanceDevice.current ?
      Math.random() > 0.7 : // Only 30% chance on low-performance devices
      Math.random() > 0.4;  // Original 60% chance
    
    // Lower collision limits for low-performance devices
    const maxCollisions = isLowPerformanceDevice.current ?
      1 : // Fixed at 1 for low-performance devices
      Math.floor(Math.random() * 3) + 1; // Original random 1-3
    
    // Store initial position
    const initialX = x + (isClick ? randomOffsetX * 1.2 : randomOffsetX);
    const initialY = y + (isClick ? randomOffsetY * 1.2 : randomOffsetY);
    
    // Shorten lifetime for low-performance devices
    const lifetimeFactor = isLowPerformanceDevice.current ? 0.65 : 0.8; // 35% reduction for low-perf
    const clickMaxLifetime = 220 * lifetimeFactor;
    const trailMaxLifetime = 150 * lifetimeFactor;
    
    return {
      id: Math.random().toString(),
      x: initialX,
      y: initialY,
      vx,
      vy,
      size: size + Math.random() * sizeVariation,
      maxSize: size + sizeVariation,
      color: getIconColor(type),
      opacity: isClick ? 0.95 : 0.85,
      rotation,
      rotationSpeed,
      lifetime: 0,
      maxLifetime: isClick ? clickMaxLifetime : trailMaxLifetime,
      iconType: type,
      iconSrc: src,
      zLayer, 
      canCollideWithElements,
      mass: size / 20 + Math.random() * 0.5,
      restitution: 0.7 + Math.random() * 0.3,
      collidedWithElement: false,
      lastElementCollision: 0,
      collisionCount: 0,
      maxCollisions: maxCollisions,
      initialX,
      initialY
    };
  }, [getRandomIcon, isLowPerformanceDevice]);

  // Function to get interactive DOM elements
  const getInteractiveElements = useCallback(() => {
    if (!shouldRefreshElements.current && interactiveElementsRef.current.length > 0) {
      return interactiveElementsRef.current;
    }

    // Get only the specific elements that should have collisions
    const selectorsToCollideWith = [
      '.features-grid', 
      '.hero-buttons',
      '.cta-title',
      '.cta-button',
      '.stat-icon',
      '.nav-dots',
      '.features-icon',
      '.features-description',
      '.payment-icon',
      '.payment-name'
    ];
    
    // For section-title, we'll get the text nodes specifically
    const sectionTitles = document.querySelectorAll('.section-title');
    
    // Store element data with their bounds
    let elementData = [];
    
    // Process regular elements
    const elements = document.querySelectorAll(selectorsToCollideWith.join(','));
    elementData = Array.from(elements).map(el => {
      const rect = el.getBoundingClientRect();
      // Check if this element is inside a payment-method
      const isInsidePaymentMethod = el.closest('.payment-method') !== null;
      
      return {
        element: el,
        rect,
        tagName: el.tagName.toLowerCase(),
        className: el.className,
        id: el.id,
        isTextNode: false,
        isInsidePaymentMethod
      };
    });
    
    // Process section titles specially - extract text nodes
    sectionTitles.forEach(titleEl => {
      // Get the actual text content area
      const textRects = [];
      const textNodes = [];
      
      // Function to recursively find text nodes
      const findTextNodes = (element) => {
        if (element.nodeType === Node.TEXT_NODE) {
          const text = element.textContent.trim();
          if (text.length > 0) {
            textNodes.push(element);
          }
        } else {
          Array.from(element.childNodes).forEach(findTextNodes);
        }
      };
      
      findTextNodes(titleEl);
      
      // Create client rects for each text node
      textNodes.forEach(textNode => {
        const range = document.createRange();
        range.selectNodeContents(textNode);
        const clientRects = Array.from(range.getClientRects());
        
        clientRects.forEach(rect => {
          if (rect.width > 5 && rect.height > 5) { // Filter out tiny rects
            textRects.push({
              element: titleEl,
              rect: rect,
              tagName: 'text',
              className: titleEl.className,
              id: titleEl.id,
              isTextNode: true,
              isInsidePaymentMethod: false
            });
          }
        });
      });
      
      // Add text rects to element data
      elementData = [...elementData, ...textRects];
    });
    
    // Cache the results
    interactiveElementsRef.current = elementData;
    shouldRefreshElements.current = false;
    
    // Set timeout to refresh elements periodically
    setTimeout(() => {
      shouldRefreshElements.current = true;
    }, 2000); // Refresh DOM element cache every 2 seconds

    return elementData;
  }, []);

  // Function to check for collisions with DOM elements
  const checkElementCollisions = useCallback((particle) => {
    // If particle has reached its collision limit, disable collisions
    if (!particle.canCollideWithElements || 
        particle.collisionCount >= particle.maxCollisions ||
        particle.lifetime - particle.lastElementCollision < 20) { // Don't check too frequently
      return particle;
    }
    
    const elements = getInteractiveElements();
    const halfSize = particle.size / 2;
    const particleLeft = particle.x - halfSize;
    const particleRight = particle.x + halfSize;
    const particleTop = particle.y - halfSize;
    const particleBottom = particle.y + halfSize;
    
    // Check for collision with each element
    for (const element of elements) {
      const { rect, className, isTextNode, isInsidePaymentMethod } = element;
      
      // Skip very small elements that are likely not visible
      if (rect.width < 10 || rect.height < 10) continue;
      
      // Skip collision with the TOP of hero-section as specified
      if (className && className.includes('hero-section') && 
          particleBottom >= rect.top && particleTop <= rect.top + 20) {
        continue;
      }
      
      // For section-title elements that aren't text nodes, skip them
      if (className && className.includes('section-title') && !isTextNode) {
        continue;
      }
      
      // For payment-method elements, only allow collisions with payment-icon and payment-name
      if (className && className.includes('payment-method') && !isInsidePaymentMethod) {
        continue;
      }
      
      // Simple rectangular collision detection
      if (particleRight >= rect.left && 
          particleLeft <= rect.right && 
          particleBottom >= rect.top && 
          particleTop <= rect.bottom) {
        
        // Determine which side of the element we hit
        const overlapLeft = particleRight - rect.left;
        const overlapRight = rect.right - particleLeft;
        const overlapTop = particleBottom - rect.top;
        const overlapBottom = rect.bottom - particleTop;
        
        // Find the smallest overlap to determine collision side
        const minOverlap = Math.min(overlapLeft, overlapRight, overlapTop, overlapBottom);
        
        let newX = particle.x;
        let newY = particle.y;
        let newVx = particle.vx;
        let newVy = particle.vy;
        
        // Apply collision response based on which side was hit
        if (minOverlap === overlapLeft) {
          // Collision from the left
          newX = rect.left - halfSize - 2; // Add 2px extra buffer
          newVx = -particle.vx * particle.restitution;
        } else if (minOverlap === overlapRight) {
          // Collision from the right
          newX = rect.right + halfSize + 2; // Add 2px extra buffer
          newVx = -particle.vx * particle.restitution;
        } else if (minOverlap === overlapTop) {
          // Collision from the top
          newY = rect.top - halfSize - 2; // Add 2px extra buffer
          newVy = -particle.vy * particle.restitution;
        } else if (minOverlap === overlapBottom) {
          // Collision from the bottom
          newY = rect.bottom + halfSize + 2; // Add 2px extra buffer
          newVy = -particle.vy * particle.restitution;
        }
        
        // Add a small random impulse for more dynamic behavior
        const randomImpulse = 0.5 + Math.random() * 1.5;
        newVx += (Math.random() - 0.5) * randomImpulse;
        newVy += (Math.random() - 0.5) * randomImpulse;
        
        // Increment collision count
        const newCollisionCount = particle.collisionCount + 1;
        
        // Determine if we've reached the collision limit
        const reachedCollisionLimit = newCollisionCount >= particle.maxCollisions;
        
        return {
          ...particle,
          x: newX,
          y: newY,
          vx: newVx,
          vy: newVy,
          collidedWithElement: true,
          lastElementCollision: particle.lifetime,
          collisionCount: newCollisionCount,
          // If reached limit, disable future collisions
          canCollideWithElements: !reachedCollisionLimit
        };
      }
    }
    
    return particle;
  }, [getInteractiveElements]);

  // Check collision between two particles
  const checkCollision = (p1, p2) => {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const minDistance = (p1.size + p2.size) / 2;
    
    // Return collision data if collision detected
    if (distance < minDistance) {
      return {
        dx,
        dy,
        distance,
        minDistance
      };
    }
    return null;
  };

  // Resolve collision between two particles
  const resolveCollision = (p1, p2, collision) => {
    // Calculate collision normal vector
    const nx = collision.dx / collision.distance;
    const ny = collision.dy / collision.distance;
    
    // Calculate overlap distance
    const overlap = collision.minDistance - collision.distance;
    
    // Move particles apart proportionally to their masses
    const totalMass = p1.mass + p2.mass;
    const p1Ratio = p1.mass / totalMass;
    const p2Ratio = p2.mass / totalMass;
    
    // Apply position correction to avoid sticking
    const correctionX = nx * overlap;
    const correctionY = ny * overlap;
    
    const p1NewX = p1.x - correctionX * p2Ratio;
    const p1NewY = p1.y - correctionY * p2Ratio;
    const p2NewX = p2.x + correctionX * p1Ratio;
    const p2NewY = p2.y + correctionY * p1Ratio;
    
    // Calculate relative velocity
    const rvx = p2.vx - p1.vx;
    const rvy = p2.vy - p1.vy;
    
    // Calculate relative velocity in terms of the normal direction
    const velAlongNormal = rvx * nx + rvy * ny;
    
    // Do not resolve if velocities are separating
    if (velAlongNormal > 0) {
      return { p1: { ...p1 }, p2: { ...p2 } };
    }
    
    // Calculate restitution (bounciness)
    const restitution = Math.min(p1.restitution, p2.restitution);
    
    // Calculate impulse scalar
    const impulseMagnitude = -(1 + restitution) * velAlongNormal;
    const impulseFactor = impulseMagnitude / totalMass;
    
    // Apply impulse
    const impulseX = nx * impulseFactor;
    const impulseY = ny * impulseFactor;
    
    // Update velocities
    const p1vx = p1.vx - impulseX * p2.mass;
    const p1vy = p1.vy - impulseY * p2.mass;
    const p2vx = p2.vx + impulseX * p1.mass;
    const p2vy = p2.vy + impulseY * p1.mass;
    
    return {
      p1: {
        ...p1,
        x: p1NewX,
        y: p1NewY,
        vx: p1vx,
        vy: p1vy
      },
      p2: {
        ...p2,
        x: p2NewX,
        y: p2NewY,
        vx: p2vx,
        vy: p2vy
      }
    };
  };

  // Check boundary collisions
  const checkBoundaries = (particle) => {
    if (!window) return particle;
    
    const halfSize = particle.size / 2;
    const maxX = window.innerWidth - halfSize;
    const minX = halfSize;
    
    let newX = particle.x;
    let newY = particle.y;
    let newVx = particle.vx;
    let newVy = particle.vy;
    
    // Bounce off left and right walls
    if (newX > maxX) {
      newX = maxX;
      newVx = -particle.vx * particle.restitution;
    } else if (newX < minX) {
      newX = minX;
      newVx = -particle.vx * particle.restitution;
    }
    
    // No bottom boundary - particles can fall through
    // But add a top boundary
    if (newY < halfSize) {
      newY = halfSize;
      newVy = -particle.vy * particle.restitution;
    }
    
    return {
      ...particle,
      x: newX,
      y: newY,
      vx: newVx,
      vy: newVy
    };
  };

  // Optimize animation loop with physics
  const animate = useCallback(() => {
    setParticles(prevParticles => {
      // Skip physics calculation if no particles (optimization)
      if (prevParticles.length === 0) return prevParticles;
      
      // Process each particle with physics
      let processedParticles = prevParticles.map(particle => {
        // Apply physics with more natural gravity but less drag for longer travel
        const newX = particle.x + particle.vx * 0.85;
        const newY = particle.y + particle.vy * 0.85;
        const newVy = particle.vy + 0.05;
        
        // Calculate fade rate based on lifetime
        let fadeRate;
        const lifetimePercentage = particle.lifetime / particle.maxLifetime;
        
        // Smooth fade out in the last 30% of lifetime
        if (lifetimePercentage > 0.7) {
          // Exponential fade out for smoother disappearance
          fadeRate = (0.5 / particle.maxLifetime) * (1 + (lifetimePercentage - 0.7) * 3);
        } else {
          // Normal fade rate for the first 70% of lifetime
          fadeRate = 0.5 / particle.maxLifetime;
        }
        
        const newOpacity = particle.opacity - fadeRate;
        const newRotation = particle.rotation + particle.rotationSpeed * 0.8;
        const newLifetime = particle.lifetime + 1;
        
        // Modified scale curve: initially grows, then shrinks
        let scale = 1;
        
        // For click particles, add a "pop" effect - initially grow larger then shrink
        if (particle.lifetime < particle.maxLifetime * 0.15) {
          // Growth phase - grow to 110% in the first 15% of lifetime
          scale = 1 + (0.1 * (particle.lifetime / (particle.maxLifetime * 0.15)));
        } else {
          // Shrink phase - start from 110% and shrink to 60%
          scale = 1.1 - (0.5 * ((particle.lifetime - (particle.maxLifetime * 0.15)) / (particle.maxLifetime * 0.85)));
        }
        
        // Ensure scale never goes below 0.6
        scale = scale > 0.6 ? scale : 0.6;
        
        return {
          ...particle,
          x: newX,
          y: newY,
          vy: newVy,
          opacity: newOpacity > 0 ? newOpacity : 0,
          rotation: newRotation,
          lifetime: newLifetime,
          size: particle.maxSize * scale
        };
      });
      
      // For low-performance devices, only perform collisions checks for a subset of particles
      // or skip collisions entirely if there are too many particles
      const skipCollisionChecks = isLowPerformanceDevice.current && processedParticles.length > 20;
      const skipParticleCollisions = isLowPerformanceDevice.current && processedParticles.length > 15;
      
      // Apply DOM element collisions first (only if not skipping)
      if (!skipCollisionChecks) {
        processedParticles = processedParticles.map(checkElementCollisions);
      }
      
      // Apply boundary collisions
      processedParticles = processedParticles.map(checkBoundaries);
      
      // Check and resolve collisions between particles (only if not skipping)
      if (!skipParticleCollisions) {
        for (let i = 0; i < processedParticles.length; i++) {
          // On low performance devices, check collisions less frequently
          const collisionStep = isLowPerformanceDevice.current ? 3 : 1;
          
          for (let j = i + collisionStep; j < processedParticles.length; j += collisionStep) {
            const collision = checkCollision(processedParticles[i], processedParticles[j]);
            
            if (collision) {
              const { p1, p2 } = resolveCollision(processedParticles[i], processedParticles[j], collision);
              processedParticles[i] = p1;
              processedParticles[j] = p2;
            }
          }
        }
      }
      
      // Filter out expired particles
      processedParticles = processedParticles.filter(particle => 
        particle.opacity > 0 && particle.lifetime < particle.maxLifetime);
      
      // Update active icons tracking
      const remainingIconTypes = new Set(processedParticles.map(p => p.iconType));
      activeIconsRef.current.clear();
      remainingIconTypes.forEach(iconType => {
        activeIconsRef.current.add(iconType);
      });
      
      return processedParticles;
    });
    
    requestRef.current = requestAnimationFrame(animate);
  }, [checkElementCollisions, checkBoundaries, checkCollision, resolveCollision, isLowPerformanceDevice]);

  // Handle mouse/touch move with proper trail generation
  const handleMove = useCallback((x, y) => {
    // Increase throttling more for low performance devices
    const throttleStep = isLowPerformanceDevice.current ? 12 : 7;
    
    if (trailThrottleRef.current % throttleStep === 0) {
      // Calculate distance from last position
      const dx = x - lastPosition.current.x;
      const dy = y - lastPosition.current.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      // Require more movement on low performance devices
      const minDistance = isLowPerformanceDevice.current ? 25 : 15;
      
      // Only add particles if mouse has moved significantly
      if (distance > minDistance) {
        const newParticle = createTrailParticle(x, y, false);
        lastPosition.current = { x, y };
        
        // Lower max particles for low performance devices
        const maxTrailParticles = isLowPerformanceDevice.current ? 20 : 35;
        setParticles(prev => [...prev, newParticle].slice(-maxTrailParticles));
      }
    }
    trailThrottleRef.current = (trailThrottleRef.current + 1) % throttleStep;
  }, [createTrailParticle, isLowPerformanceDevice]);

  // Create a burst of particles on click
  const createBurst = useCallback((x, y) => {
    // Check for rapid consecutive clicks
    const now = Date.now();
    const timeSinceLastClick = now - lastClickTimeRef.current;
    
    // If clicks are happening in rapid succession (within 500ms)
    if (timeSinceLastClick < 500) {
      clickCountRef.current++;
    } else {
      clickCountRef.current = 0;
    }
    lastClickTimeRef.current = now;
    
    // Limit particles created based on device performance and click frequency
    let burstParticleCount = 10;
    
    if (isLowPerformanceDevice.current) {
      // Reduce base count for low-performance devices
      burstParticleCount = 6;
      
      // Further reduce for rapid clicks
      if (clickCountRef.current > 2) {
        burstParticleCount = 3;
      }
      if (clickCountRef.current > 5) {
        burstParticleCount = 1; // Minimal burst for very rapid clicking
      }
      if (clickCountRef.current > 10) {
        return; // Skip burst entirely for extreme rapid clicking
      }
    } else {
      // Gradual reduction for standard devices
      if (clickCountRef.current > 5) {
        burstParticleCount = 7;
      }
      if (clickCountRef.current > 10) {
        burstParticleCount = 4;
      }
    }
    
    setParticles(prevParticles => {
      // Lower max particles for low performance devices
      const maxParticles = isLowPerformanceDevice.current ? 25 : 40;
      let newParticles = [...prevParticles];
      
      // If we're going to exceed the limit, fade out the oldest particles
      if (newParticles.length + burstParticleCount > maxParticles) {
        // Sort by lifetime to find the oldest
        newParticles.sort((a, b) => b.lifetime - a.lifetime);
        
        // Mark the oldest particles for faster fade-out
        const particlesToFade = newParticles.slice(0, burstParticleCount);
        particlesToFade.forEach(particle => {
          particle.opacity *= 0.7; // Reduce opacity immediately
          particle.maxLifetime = Math.min(particle.maxLifetime, particle.lifetime + 20); // Shorten remaining lifetime
        });
        
        // Keep only the particles that still have reasonable lifetime left
        newParticles = newParticles.filter(p => p.maxLifetime - p.lifetime > 15);
      }
      
      // Create particles for the burst with no duplicates
      const burstParticles = [];
      const usedIconTypes = new Set(); // Track used icon types within this burst
      
      // Try to create unique particles but limit attempts
      let attempts = 0;
      // Reduced max attempts for low-performance devices
      const maxAttempts = isLowPerformanceDevice.current ? 12 : 20;
      
      while (burstParticles.length < burstParticleCount && attempts < maxAttempts) {
        attempts++;
        const newParticle = createTrailParticle(x, y, true);
        
        // Skip if we've already used this icon type in this burst
        if (usedIconTypes.has(newParticle.iconType)) {
          continue;
        }
        
        // Add to used set and particle array
        usedIconTypes.add(newParticle.iconType);
        burstParticles.push(newParticle);
      }
      
      return [...newParticles, ...burstParticles];
    });
  }, [createTrailParticle, isLowPerformanceDevice]);

  // Mouse event handlers
  const handleMouseMove = useCallback((e) => {
    handleMove(e.clientX, e.clientY);
  }, [handleMove]);
  
  const handleMouseClick = useCallback((e) => {
    createBurst(e.clientX, e.clientY);
  }, [createBurst]);
  
  // Touch event handlers
  const handleTouchMove = useCallback((e) => {
    if (e.touches && e.touches[0]) {
      handleMove(e.touches[0].clientX, e.touches[0].clientY);
    }
  }, [handleMove]);
  
  const handleTouchEnd = useCallback((e) => {
    if (e.changedTouches && e.changedTouches[0]) {
      createBurst(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }
  }, [createBurst]);

  // Set up event listeners and animation
  useEffect(() => {
    // Initialize last position to prevent jumps
    if (typeof window !== 'undefined') {
      lastPosition.current = { 
        x: window.innerWidth / 2, 
        y: window.innerHeight / 2 
      };
    }
    
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('click', handleMouseClick);
    window.addEventListener('touchmove', handleTouchMove, { passive: true });
    window.addEventListener('touchend', handleTouchEnd);
    
    requestRef.current = requestAnimationFrame(animate);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleMouseClick);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      cancelAnimationFrame(requestRef.current);
    };
  }, [animate, handleMouseMove, handleMouseClick, handleTouchMove, handleTouchEnd]);

  // Optimize getParticleStyle for better performance
  const getParticleStyle = (particle) => {
    // Get current mouse position
    const mouseX = lastPosition.current.x;
    const mouseY = lastPosition.current.y;
    
    // Calculate distance from current mouse position
    const dx = particle.x - mouseX;
    const dy = particle.y - mouseY;
    const distanceFromMouse = Math.sqrt(dx * dx + dy * dy);
    
    // Calculate opacity based on distance from mouse
    let opacityModifier = 1; // Default: no change to opacity
    
    // Only reduce opacity when particle is within 50px of the mouse
    if (distanceFromMouse < 50) {
      // Linear scale: 0.4 (40% opacity) when at cursor, gradually increasing to full opacity at 50px
      opacityModifier = 0.4 + (0.6 * (distanceFromMouse / 50));
    }
    
    // Calculate lifetime percentage for transition effects
    const lifetimePercentage = particle.lifetime / particle.maxLifetime;
    
    // Determine z-index - simplified for low-performance devices
    let zIndex;
    if (isLowPerformanceDevice.current) {
      // Simplified z-index calculation
      zIndex = particle.zLayer <= 1 ? 0 : 100;
    } else {
      // Original z-index calculation
      zIndex = particle.zLayer === 0 ? -1 : 
               particle.zLayer === 1 ? 0 : 
               particle.zLayer === 2 ? 50 : 
               particle.zLayer === 3 ? 100 : 1000;
    }
    
    // Create style object with only necessary properties
    const baseStyle = {
      left: `${particle.x}px`,
      top: `${particle.y}px`,
      width: `${particle.size}px`,
      height: `${particle.size}px`,
      opacity: particle.opacity * opacityModifier,
      transform: `rotate(${particle.rotation}deg)`,
      backgroundColor: `${particle.color}18`,
      zIndex: zIndex,
      // Only apply transitions for particles in critical phases (reduces style recalculations)
      transition: lifetimePercentage > 0.7 || particle.collisionCount >= particle.maxCollisions 
                  ? 'opacity 0.3s, transform 0.2s' 
                  : ''
    };
    
    // Add extra properties only when needed
    if (particle.collidedWithElement && particle.collisionCount < particle.maxCollisions) {
      baseStyle.boxShadow = `0 0 8px ${particle.color}`;
      baseStyle.backgroundColor = `${particle.color}30`;
    } else if (particle.collisionCount >= particle.maxCollisions) {
      baseStyle.backgroundColor = `${particle.color}10`;
    } else {
      baseStyle.boxShadow = `0 0 4px rgba(255,255,255,0.4)`;
    }
    
    // Apply scale transform only for particles near the end of their lifetime
    if (lifetimePercentage > 0.85) {
      baseStyle.transform += ` scale(${1 - (lifetimePercentage - 0.85) * 2})`;
    }
    
    return baseStyle;
  };

  return (
    <div className="mouse-trail-container">
      {particles.map(particle => (
        <div
          key={particle.id}
          className="mouse-trail-particle"
          style={getParticleStyle(particle)}
        >
          <img 
            src={particle.iconSrc} 
            alt={particle.iconType}
            style={{
              width: '90%',
              height: '90%',
              objectFit: 'contain'
            }}
          />
        </div>
      ))}
    </div>
  );
};

const Home = () => {
  const containerRef = useRef(null);
  const [faqs, setFaqs] = useState([]);
  const [activeSection, setActiveSection] = useState(0);
  const [navVisible, setNavVisible] = useState(false);
  const [announcement, setAnnouncement] = useState("Welcome! All products are ethically sourced through strategically selected regional rates, reducing subscription costs and empowering you with a wide range of choices for entertainment, productivity, and more!");
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  
  // Feature carousel state
  const [activeFeatureSet, setActiveFeatureSet] = useState(0);
  const [autoRotateFeatures, setAutoRotateFeatures] = useState(true);
  const [featureTransitioning, setFeatureTransitioning] = useState(false);
  const featureSets = [
    [Features[0], Features[1]],  // First set of two features
    [Features[2], Features[3]]   // Second set of two features
  ];
  
  // References for sections and container
  const sectionRefs = useRef([]);
  
  // Simplify scroll management - just track if we're in transition
  const [isTransitioning, setIsTransitioning] = useState(false);
  // Keep the time management for settling period
  const [lastScrollTime, setLastScrollTime] = useState(0);
  // Add state to track if scroll has been released
  const [scrollLocked, setScrollLocked] = useState(false);
  
  // Set longer settling period to prevent rapid scrolling
  const settlingPeriod = 1200; // 1.2 seconds settling period
  
  // Initialize section refs
  useEffect(() => {
    sectionRefs.current = Array(5).fill().map((_, i) => sectionRefs.current[i] || React.createRef());
  }, []);
  
  // Fetch FAQs on component mount
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const fetchedFaqs = await requests.getFaqs();
        if (fetchedFaqs && fetchedFaqs.length > 0) {
          setFaqs(fetchedFaqs);
        } else {
          // If the API returns no FAQs, use the sample FAQs as fallback
          setFaqs(SampleFAQs);
        }
      } catch (error) {
        console.log("Error fetching FAQs:", error);
        // Use sample FAQs as fallback if the API call fails
        setFaqs(SampleFAQs);
      }
    };
    
    fetchFaqs();
  }, []);
  
  // Auto-rotate features
  useEffect(() => {
    let rotationInterval;
    
    if (autoRotateFeatures && !featureTransitioning) {
      rotationInterval = setInterval(() => {
        // Start transition
        setFeatureTransitioning(true);
        
        // After a short delay, change the set
        setTimeout(() => {
          setActiveFeatureSet(prev => (prev + 1) % featureSets.length);
          
          // After changing set, wait for animations to complete
          setTimeout(() => {
            setFeatureTransitioning(false);
            
            // Resume auto-rotation after a longer delay
            setTimeout(() => {
              setAutoRotateFeatures(true);
            }, 10000);
          }, 800);
        }, 300);
      }, 6000); // Rotate every 6 seconds
    }
    
    return () => {
      if (rotationInterval) {
        clearInterval(rotationInterval);
      }
    };
  }, [autoRotateFeatures, featureSets.length, featureTransitioning]);
  
  // Additional improvements for the scrolling and section transitions
  useEffect(() => {
    const observeIntersection = () => {
      const sections = document.querySelectorAll('.section');
      const navDots = document.querySelectorAll('.nav-dot');
      
      // Create observer with more sensitive threshold and margins
      const observerOptions = {
        root: null,
        rootMargin: "-10% 0px -20% 0px", /* Adjust rootMargin to detect sections earlier */
        threshold: [0.1, 0.25, 0.5, 0.75] /* Multiple thresholds for better detection */
      };
      
      const observer = new IntersectionObserver((entries) => {
        // Find the most visible section
        let maxVisibility = 0;
        let mostVisibleSectionIndex = -1;
        
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const sectionIndex = Array.from(sections).indexOf(entry.target);
            if (entry.intersectionRatio > maxVisibility) {
              maxVisibility = entry.intersectionRatio;
              mostVisibleSectionIndex = sectionIndex;
            }
          }
        });
        
        // Update active section if we found one and it's different from current
        if (mostVisibleSectionIndex >= 0 && mostVisibleSectionIndex !== activeSection) {
          setActiveSection(mostVisibleSectionIndex);
          
          // Update dots visually
          navDots.forEach((dot, idx) => {
            if (idx === mostVisibleSectionIndex) {
              dot.classList.add('active');
            } else {
              dot.classList.remove('active');
            }
          });
        }
      }, observerOptions);
      
      // Start observing all sections
      sections.forEach(section => {
        observer.observe(section);
      });
      
      return () => {
        sections.forEach(section => {
          observer.unobserve(section);
        });
      };
    };
    
    // Initialize intersection observer for section detection
    const observer = observeIntersection();
    
    // Initialize scroll behavior enhancement
    enhanceFAQBehavior();
    
    // Initialize keyboard navigation
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (activeSection < 4) {
            scrollToSection(activeSection + 1);
          }
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (activeSection > 0) {
            scrollToSection(activeSection - 1);
          }
          break;
        default:
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    // Connect dot navigation on click with active state
    const sectionDots = document.querySelectorAll('.section-dot');
    sectionDots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        scrollToSection(index);
        
        // Immediately update active dot for instant feedback
        sectionDots.forEach((d, i) => {
          if (i === index) {
            d.classList.add('active');
          } else {
            d.classList.remove('active');
          }
        });
      });
    });
    
    return () => {
      if (observer && typeof observer.disconnect === 'function') {
        observer.disconnect();
      }
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  // Add a ref to store the scroll handler for proper cleanup
  const faqScrollHandler = useRef(null);
  
  // Enhance accordion behavior for the FAQ section
  const enhanceFAQBehavior = () => {
    // Adjust the last accordion items to prevent overflow
    const adjustLastAccordionItems = () => {
      const faqSection = sectionRefs.current[3]?.current;
      if (!faqSection) return;
      
      const faqContainer = faqSection.querySelector('.faq-container');
      
      // Create handler function and store in ref for later cleanup
      faqScrollHandler.current = () => {
        faqScrollPosition.current = faqContainer.scrollTop;
      };
      
      if (faqContainer) {
        // Add scroll listener to capture scroll position in real-time
        faqContainer.addEventListener('scroll', faqScrollHandler.current);
      }
      
      const accordionItems = faqSection.querySelectorAll('.accordion-item');
      
      // Special handling for the last two items
      const lastItems = Array.from(accordionItems).slice(-2);
      lastItems.forEach(item => {
        item.classList.add('last-items');
        
        // Add a special click handler for last items
        item.addEventListener('click', (event) => {
          if (item.classList.contains('active-accordion-item')) {
            // When expanded, ensure it doesn't overflow
            const contentEl = item.querySelector('.accordion-content');
            const faqContainerRect = faqContainer.getBoundingClientRect();
            const itemRect = item.getBoundingClientRect();
            const headerHeight = item.querySelector('.accordion-header').offsetHeight;
            
            // Calculate available space
            const availableSpace = faqContainerRect.bottom - itemRect.top - headerHeight - 40;
            
            // Set max-height to ensure it doesn't overflow
            if (contentEl) {
              contentEl.style.maxHeight = Math.min(200, availableSpace) + 'px';
            }
          }
        });
      });
    };
    
    // Call on load and on resize
    adjustLastAccordionItems();
    window.addEventListener('resize', adjustLastAccordionItems);
    
    return () => {
      window.removeEventListener('resize', adjustLastAccordionItems);
      
      // Clean up scroll listener
      const faqSection = sectionRefs.current[3]?.current;
      if (faqSection) {
        const faqContainer = faqSection.querySelector('.faq-container');
        if (faqContainer && faqScrollHandler.current) {
          faqContainer.removeEventListener('scroll', faqScrollHandler.current);
        }
      }
    };
  };

  // Call the FAQ enhancement on component mount
  useEffect(() => {
    const cleanup = enhanceFAQBehavior();
    return cleanup;
  }, []);
  
  // Initialize accordions whenever faqs state is updated
  useEffect(() => {
    if (faqs.length > 0) {
      // Simple direct approach - no cloning, direct event listeners
      const initializeAccordions = () => {
        const accordionItems = document.querySelectorAll(".accordion-item");
        accordionItems.forEach(item => {
          // Remove any existing listeners first (to prevent duplicates)
          const newItem = item.cloneNode(true);
          item.parentNode.replaceChild(newItem, item);
          
          // Add click listener to each accordion item
          newItem.addEventListener("click", function() {
            const isActive = this.classList.contains("active-accordion-item");
            
            // Close other accordions
            document.querySelectorAll(".active-accordion-item").forEach(openItem => {
              if (openItem !== this) {
                openItem.classList.remove("active-accordion-item");
              }
            });
            
            // Toggle this accordion
            if (isActive) {
              this.classList.remove("active-accordion-item");
            } else {
              this.classList.add("active-accordion-item");
            }
          });
        });
      };
      
      // Initialize after a small delay to ensure DOM is ready
      setTimeout(initializeAccordions, 100);
    }
  }, [faqs]);
  
  // Update the wheel handler to ensure scroll direction is always respected
  const handleWheel = (e) => {
    // Always prevent continuous scrolling
    if (isTransitioning || scrollLocked) {
      e.preventDefault();
      return;
    }
    
    // Get current active section
    const currentSection = sectionRefs.current[activeSection]?.current;
    if (!currentSection) return;
    
    const isFAQSection = activeSection === 3;
    
    // For FAQ section, we need more complex scrolling logic
    if (isFAQSection) {
      const faqContainer = currentSection.querySelector('.faq-container');
      
      if (faqContainer) {
        const { scrollTop, scrollHeight, clientHeight } = faqContainer;
        
        // SCROLLING DOWN
        if (e.deltaY > 0) {
          // Check if we're at the bottom of the FAQ container
          const isAtBottom = scrollTop + clientHeight >= scrollHeight - 5;
          
          // We'll use three states for bottom scrolling:
          // 1. Not at bottom yet -> allow normal scrolling
          // 2. Just reached bottom (first time) -> set flag, block scrolling
          // 3. Already at bottom with flag set + new wheel event after 1000ms -> navigate
          
          if (isAtBottom) {
            console.log("At bottom of FAQ section");
            const now = Date.now();
            
            // First time reaching bottom - set timestamp and block further scrolling
            if (!faqContainer.dataset.bottomReachedAt) {
              console.log("First time reaching bottom - waiting for second scroll");
              faqContainer.dataset.bottomReachedAt = now.toString();
              e.preventDefault();
              return;
            }
            
            // Check if enough time has passed for this to be a distinct second scroll
            const bottomReachedAt = parseInt(faqContainer.dataset.bottomReachedAt, 10);
            const timeSinceBottomReached = now - bottomReachedAt;
            
            // If it's been at least 1000ms since first reaching bottom, this is a new scroll attempt
            if (timeSinceBottomReached > 1000) {
              console.log("Second scroll detected after pause - navigating to next section");
              
              // Clear the flag
              delete faqContainer.dataset.bottomReachedAt;
              
              // Navigate to next section
              setScrollLocked(true);
              setIsTransitioning(true);
              setActiveSection(4); // CTA section
              scrollToSection(4);
              e.preventDefault();
              
              // Reset locks after transition
              setTimeout(() => {
                setScrollLocked(false);
                setIsTransitioning(false);
              }, settlingPeriod);
              
              return;
            } else {
              // Still in the cooldown period, prevent scrolling
              console.log("In cooldown period - ignoring scroll");
              e.preventDefault();
              return;
            }
          } else {
            // Not at bottom, clear any "at bottom" flag if scrolling within content
            delete faqContainer.dataset.bottomReachedAt;
            // Allow normal scrolling
            return;
          }
        }
        // SCROLLING UP
        else if (e.deltaY < 0) {
          // Check if we're at the top of the FAQ container
          const isAtTop = scrollTop <= 0;
          
          // Similar three states for top scrolling
          if (isAtTop) {
            console.log("At top of FAQ section");
            const now = Date.now();
            
            // First time reaching top - set timestamp and block further scrolling
            if (!faqContainer.dataset.topReachedAt) {
              console.log("First time reaching top - waiting for second scroll");
              faqContainer.dataset.topReachedAt = now.toString();
              e.preventDefault();
              return;
            }
            
            // Check if enough time has passed for this to be a distinct second scroll
            const topReachedAt = parseInt(faqContainer.dataset.topReachedAt, 10);
            const timeSinceTopReached = now - topReachedAt;
            
            // If it's been at least 1000ms since first reaching top, this is a new scroll attempt
            if (timeSinceTopReached > 1000) {
              console.log("Second scroll detected after pause - navigating to previous section");
              
              // Clear the flag
              delete faqContainer.dataset.topReachedAt;
              
              // Navigate to previous section
              setScrollLocked(true);
              setIsTransitioning(true);
              setActiveSection(2); // Payment section
              scrollToSection(2);
              e.preventDefault();
              
              // Reset locks after transition
              setTimeout(() => {
                setScrollLocked(false);
                setIsTransitioning(false);
              }, settlingPeriod);
              
              return;
            } else {
              // Still in the cooldown period, prevent scrolling
              console.log("In cooldown period - ignoring scroll");
              e.preventDefault();
              return;
            }
          } else {
            // Not at top, clear any "at top" flag if scrolling within content
            delete faqContainer.dataset.topReachedAt;
            // Allow normal scrolling
            return;
          }
        }
      }
    }
    
    // For non-FAQ sections, strictly enforce one-section-at-a-time navigation
    if (e.deltaY > 0 && activeSection < sectionRefs.current.length - 1) {
      setScrollLocked(true);
      setIsTransitioning(true);
      setLastScrollTime(Date.now());
      setActiveSection(activeSection + 1);
      scrollToSection(activeSection + 1);
      e.preventDefault();
      
      // Reset scroll lock after settling period
      setTimeout(() => {
        setScrollLocked(false);
        setIsTransitioning(false);
      }, settlingPeriod);
    } else if (e.deltaY < 0 && activeSection > 0) {
      setScrollLocked(true);
      setIsTransitioning(true);
      setLastScrollTime(Date.now());
      setActiveSection(activeSection - 1);
      scrollToSection(activeSection - 1);
      e.preventDefault();
      
      // Reset scroll lock after settling period
      setTimeout(() => {
        setScrollLocked(false);
        setIsTransitioning(false);
      }, settlingPeriod);
    }
  };
  
  // Update scrollToSection to work with the new transition system
  const scrollToSection = (index) => {
    if (index < 0 || index >= sectionRefs.current.length || isTransitioning) {
      return;
    }
    
    // Signal that we're in a transition
    setNavVisible(true);
    setIsTransitioning(true);
    setScrollLocked(true);
    setActiveSection(index);
    
    // Close any open accordions when changing sections
    if (index !== 3) { // 3 is the FAQ section index
      const openAccordions = document.querySelectorAll('.active-accordion-item');
      openAccordions.forEach(item => {
        item.classList.remove('active-accordion-item');
      });
    }
    
    // For the FAQ section, restore previous scroll position if available
    if (index === 3) {
      setTimeout(() => {
        const faqContainer = document.querySelector('.faq-container');
        if (faqContainer) {
          faqContainer.scrollTop = faqScrollPosition.current;
        }
      }, 100);
    }
    
    const section = sectionRefs.current[index].current;
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    
    // Reset section content scroll position
    const sectionContent = section.querySelector('.section-content');
    if (sectionContent) {
      sectionContent.scrollTop = 0;
    }
    
    // Reset transition states after animation completes
    setTimeout(() => {
      setNavVisible(false);
      setIsTransitioning(false);
      setScrollLocked(false);
    }, settlingPeriod);
  };
  
  // Restore keyboard navigation
  useEffect(() => {
    // Handle keyboard navigation
    const handleKeyDown = (e) => {
      if (isTransitioning || scrollLocked) return;
      
      // Arrow key navigation
      if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
        if (activeSection < sectionRefs.current.length - 1) {
          setScrollLocked(true);
          scrollToSection(activeSection + 1);
        }
      } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
        if (activeSection > 0) {
          setScrollLocked(true);
          scrollToSection(activeSection - 1);
        }
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeSection, isTransitioning, scrollLocked]);

  // Add new useEffect specifically for ensuring first dot is active on mount
  useEffect(() => {
    // Ensure first dot is highlighted on initial load
    setTimeout(() => {
      const navDots = document.querySelectorAll('.nav-dot');
      if (navDots.length > 0) {
        // Force first dot to be active on initial load
        navDots.forEach((dot, idx) => {
          if (idx === 0) {
            dot.classList.add('active');
          } else {
            dot.classList.remove('active');
          }
        });
        
        // Force activeSection state to be 0
        setActiveSection(0);
      }
    }, 200);
  }, []);

  /* Section-specific gradient system */
  // Define section-specific gradients with distinct identity for each section
  const sectionGradients = {
    0: { // Hero section
      start: '#4364F7',
      end: '#6FB1FC',
      css: 'linear-gradient(to bottom, #4364F7 0%, #6FB1FC 100%)'
    },
    1: { // Features section
      start: '#6FB1FC',
      end: '#5D6BFF',
      css: 'linear-gradient(to bottom, #6FB1FC 0%, #5D6BFF 100%)'
    },
    2: { // Payments section
      start: '#5D6BFF',
      end: '#00C6FB',
      css: 'linear-gradient(to bottom, #5D6BFF 0%, #00C6FB 100%)'
    },
    3: { // FAQ section
      start: '#00C6FB',
      end: '#A85CE6',
      css: 'linear-gradient(to bottom, #00C6FB 0%, #A85CE6 100%)'
    },
    4: { // CTA section
      start: '#A85CE6',
      end: '#F5426C',
      css: 'linear-gradient(to bottom, #A85CE6 0%, #F5426C 100%)'
    }
  };

  // Define the handleScroll function at component level
  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const sections = document.querySelectorAll('.section');
    const viewportHeight = window.innerHeight;

    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      const visibilityRatio = Math.max(0, Math.min(1, (viewportHeight - Math.abs(rect.top)) / viewportHeight));

      if (visibilityRatio > 0.5) {
        container.style.background = sectionGradients[index].css;
        setActiveSection(index);

        const navDots = document.querySelectorAll('.nav-dot');
        navDots.forEach((dot, idx) => {
          dot.classList.toggle('active', idx === index);
        });
      }
    });
  };

  // Apply section gradients function at component level
  const applySectionGradients = () => {
    const sections = document.querySelectorAll('.section');
    sections.forEach((section, index) => {
      if (sectionGradients[index]) {
        // Apply section-specific gradient with a subtle transparency
        section.style.background = sectionGradients[index].css;
        // Add a semi-transparent overlay for content readability
        section.style.boxShadow = 'inset 0 0 0 1000px rgba(0, 0, 0, 0.05)';
      }
    });
  };

  // Single useEffect for handling gradients and scroll behavior
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const debouncedHandleScroll = debounce(handleScroll, 10);

      // Initial application
      applySectionGradients();
      handleScroll(); // Initial call

      container.addEventListener('scroll', debouncedHandleScroll);
      window.addEventListener('resize', debouncedHandleScroll);

      return () => {
        container.removeEventListener('scroll', debouncedHandleScroll);
        window.removeEventListener('resize', debouncedHandleScroll);
      };
    }
  }, [activeSection]);

  // Handle announcement visibility on scroll
  useEffect(() => {
    const handleAnnouncementScroll = () => {
      if (window.scrollY > 10 || (containerRef.current && containerRef.current.scrollTop > 10)) {
        setShowAnnouncement(false);
      }
    };

    window.addEventListener('scroll', handleAnnouncementScroll);
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleAnnouncementScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleAnnouncementScroll);
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleAnnouncementScroll);
      }
    };
  }, []);

  // Add scroll release detection
  useEffect(() => {
    const handleScrollStop = debounce(() => {
      setScrollLocked(false);
    }, 300);
    
    window.addEventListener('wheel', handleScrollStop, { passive: true });
    
    return () => {
      window.removeEventListener('wheel', handleScrollStop);
    };
  }, []);
  
  // Attach main wheel handler
  useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false });
    
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [activeSection, isTransitioning, scrollLocked, lastScrollTime]);
  
  // Styles to ensure section headers and other elements don't break the gradient
  const dynamicStyles = `
    .sections-container {
      position: relative;
      height: calc(100vh - 48px); /* Adjust for fixed header height */
      overflow-y: auto;
      overflow-x: hidden;
      scroll-snap-type: y mandatory;
      scroll-behavior: smooth;
      padding: 0;
      transition: background 0.3s ease-out;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    
    .sections-container::-webkit-scrollbar {
      display: none;
    }
    
    /* Ensure section-specific gradient display */
    .section {
      position: relative;
      min-height: 100vh;
      width: 100%;
      scroll-snap-align: start;
      overflow: hidden;
      box-sizing: border-box;
      isolation: isolate;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      z-index: 1;
      transition: background 0.3s ease-out;
    }
    
    /* CRITICAL: Remove any background colors from section headers and content */
    .section-header, 
    .section-content,
    .faq-section .section-header,
    .faq-container,
    .section-tag,
    .section-title {
      background: transparent !important;
      background-color: transparent !important;
      background-image: none !important;
      box-shadow: none !important;
    }
    
    /* Ensure clean content containers */
    .section-content {
      position: relative;
      z-index: 2;
      padding: 80px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-height: calc(100vh - 100px);
      background: transparent !important;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    
    .section-content::-webkit-scrollbar {
      display: none;
    }
    
    /* First section needs extra padding for header */
    .hero-section .section-content {
      padding-top: 120px;
      padding-bottom: 80px;
    }
    
    /* Add subtle text contrast for readability */
    .section-tag,
    .section-title,
    .feature-title,
    .feature-description,
    .payment-name,
    .accordion-header h3,
    .accordion-content p,
    .cta-title,
    .cta-description,
    .cta-support {
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
    
    /* Section header styling */
    .section-header {
      margin-bottom: 40px;
      text-align: center;
      width: 100%;
      position: relative;
      z-index: 3;
    }

    /* Floating Announcement Popup */
    .floating-announcement {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(10px);
      max-width: 90%;
      width: auto;
      padding: 12px 18px;
      border-radius: 12px;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: fadeInUp 0.5s ease-out;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translate(-50%, 20px);
      }
      to {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }

    .announcement-content {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      max-width: calc(100% - 30px);
    }

    .announcement-badge {
      background: rgba(255, 255, 255, 0.15);
      color: #fff;
      font-size: 0.7rem;
      font-weight: 600;
      padding: 3px 8px;
      border-radius: 12px;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }

    .announcement-text {
      font-weight: 400;
      letter-spacing: 0.2px;
      font-size: 0.9rem;
    }

    .announcement-close {
      background: transparent;
      border: none;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.5rem;
      line-height: 1;
      cursor: pointer;
      padding: 0 5px;
      margin-left: 10px;
      transition: color 0.2s;
    }

    .announcement-close:hover {
      color: #fff;
    }
  `;

  // Inject SVG for Litecoin icon
  useEffect(() => {
    // Function to inject the Litecoin SVG
    const injectLitecoinSvg = () => {
      const litecoinElements = document.querySelectorAll('.litecoin-icon');
      litecoinElements.forEach(element => {
        if (element && !element.innerHTML) {
          element.innerHTML = litecoinSvg;
        }
      });
    };
    
    // Inject after component mounts and whenever the DOM might update
    injectLitecoinSvg();
    
    // Save reference for cleanup
    const observer = new MutationObserver(injectLitecoinSvg);
    observer.observe(document.body, { childList: true, subtree: true });
    
    return () => observer.disconnect();
  }, []);

  // Add a ref to store the FAQ scroll position
  const faqScrollPosition = useRef(0);

  // Add this useEffect to save scroll position when leaving FAQ section
  useEffect(() => {
    // Save FAQ scroll position when leaving the section
    if (activeSection !== 3) {
      const saveFaqScrollPosition = () => {
        const faqContainer = document.querySelector('.faq-container');
        if (faqContainer) {
          faqScrollPosition.current = faqContainer.scrollTop;
        }
      };
      
      // Save position immediately when we leave the section
      saveFaqScrollPosition();
    }
  }, [activeSection]);

  return (
    <div className="fullpage-container">
      {/* Add the MouseTrailEffect component */}
      <MouseTrailEffect />
      
      {/* Fixed Header */}
      <header className="fixed-header">
        <nav className="main-nav">
          <div className="container">
            <div className="nav-content">
              <Link to="/" className="brand-logo">LIMITLESS</Link>
              <div className="nav-links">
                <Link to="/shop" className="nav-link">Shop</Link>
                <Link to="/tickets" className="nav-link">Support</Link>
                <Link to="/signup" className="signup-button">Create Account</Link>
                <Link to="/login" className="login-button">Login</Link>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Floating announcement popup */}
      {showAnnouncement && (
        <div className="floating-announcement">
          <div className="announcement-content">
            <span className="announcement-badge">ETHICAL</span>
            <span className="announcement-text">{announcement}</span>
          </div>
          <button 
            className="announcement-close" 
            onClick={() => setShowAnnouncement(false)}
            aria-label="Close announcement"
          >
            ×
          </button>
        </div>
      )}

      {/* Section Navigation Dots */}
      <div className="nav-dots">
        {[0, 1, 2, 3, 4].map((index) => (
          <button
            key={index}
            onClick={() => scrollToSection(index)}
            aria-label={`Go to section ${index + 1}`}
            className={`nav-dot ${activeSection === index ? 'active' : ''}`}
          />
        ))}
      </div>

      {/* Main Content */}
      <div className="sections-container" ref={containerRef}>
        {/* Hero Section */}
        <section className="section hero-section" ref={sectionRefs.current[0]}>
          <div className="container section-content">
            <div className="hero-content">
              <h5 className="hero-subtitle">
                <strong>#1</strong> <span className="subtle-accent">PREMIUM SUBSCRIPTIONS AND PRODUCTS</span> <strong>STORE</strong>
              </h5>
              <h1 className="hero-title">
                <span className="title-main">LIMITLESS</span><span className="title-dot">.</span>STORE
              </h1>
              <p className="hero-description">
                We offer the most affordable, <span className="subtle-highlight">ethically sourced</span>, high-quality subscriptions available. After 6 years of stellar service, we've earned exceptional feedback through our responsive support and continuous improvement. By prioritizing <span className="subtle-highlight">user experience</span> and evolving our sourcing methods, we help you save valuable time and money for what matters most. Don't just take our word for it—check our reviews, ask a friend, or contact us directly with any questions!
              </p>
              <div className="hero-buttons">
                <Link 
                  to="/shop" 
                  className="primary-button" 
                  replace
                  onClick={prefetchShopData}
                >
                  Explore Store <i className="fas fa-arrow-right"></i>
                </Link>
                <a href="#features" className="secondary-button" onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(1);
                }}>
                  Learn More
                </a>
              </div>
            </div>

            <div className="hero-stats">
              <ul className="stats-list">
                {Stats.map((stat, index, array) => (
                  <React.Fragment key={index}>
                    <li className="stat-item">
                      <div className="stat-icon">
                        <i className={
                          index === 0 ? "fas fa-shopping-cart" : 
                          index === 1 ? "fas fa-star" : 
                          "fas fa-box"
                        }></i>
                      </div>
                      <h4 className="stat-number">{stat.number}</h4>
                      <span className="stat-label">{stat.label}</span>
                    </li>
                    {index < array.length - 1 && <li className="stat-divider"></li>}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="section features-section" id="features" ref={sectionRefs.current[1]}>
          <div className="container section-content">
            <div className="section-header">
              <h6 className="section-tag">FEATURES</h6>
              <h2 className="section-title">Why Choose Us?</h2>
            </div>
            
            <div 
              className={`features-grid ${featureTransitioning ? 'transitioning' : ''}`} 
              key={`feature-set-${activeFeatureSet}`}
            >
              {featureSets[activeFeatureSet].map((feature, index) => (
                <div className="feature-card" key={index}>
                  <div className="feature-icon">
                    <i className={`fas ${feature.icon}`}></i>
                  </div>
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </div>
              ))}
            </div>
            
            {/* Features navigation dots */}
            <div className="features-nav-dots">
              {featureSets.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    if (featureTransitioning || index === activeFeatureSet) return;
                    
                    // Start transition
                    setFeatureTransitioning(true);
                    setAutoRotateFeatures(false);
                    
                    // After a short delay, change the set
                    setTimeout(() => {
                      setActiveFeatureSet(index);
                      
                      // After changing set, wait for animations to complete
                      setTimeout(() => {
                        setFeatureTransitioning(false);
                        
                        // Resume auto-rotation after a longer delay
                        setTimeout(() => {
                          setAutoRotateFeatures(true);
                        }, 10000);
                      }, 800);
                    }, 300);
                  }}
                  aria-label={`Go to feature set ${index + 1}`}
                  className={`feature-nav-dot ${activeFeatureSet === index ? 'active' : ''}`}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Payment Section */}
        <section className="section payment-section" ref={sectionRefs.current[2]}>
          <div className="container section-content">
            <div className="section-header">
              <h6 className="section-tag">PAYMENTS</h6>
              <h2 className="section-title">We Accept Payments From</h2>
            </div>
            
            <div className="payment-methods">
              {PaymentMethods.map((method, index) => (
                <div className="payment-method" key={index}>
                  <div className="payment-icon">
                    <i className={method.icon}></i>
                  </div>
                  <h4 className="payment-name">{method.name}</h4>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="section faq-section" ref={sectionRefs.current[3]}>
          <div className="container section-content">
            <div className="section-header">
              <h6 className="section-tag">FAQ</h6>
              <h2 className="section-title">Frequently Asked Questions</h2>
            </div>

            <div className="faq-container">
              {faqs.map((faq, index) => (
                <div 
                  className="accordion-item" 
                  key={index}
                  onClick={(e) => {
                    // Get the clicked accordion item
                    const card = e.currentTarget;
                    const wasActive = card.classList.contains("active-accordion-item");
                    
                    // Close any open accordion
                    const open = document.querySelectorAll(".active-accordion-item");
                    if (open.length) {
                      open.forEach(item => {
                        if (item !== card) {
                          item.classList.remove("active-accordion-item");
                        }
                      });
                    }
                    
                    // Toggle the current one
                    if (wasActive) {
                      card.classList.remove("active-accordion-item");
                    } else {
                      card.classList.add("active-accordion-item");
                    }
                  }}
                >
                  <div className="accordion-header">
                    <h3>{faq.question}</h3>
                    <span className="accordion-icon">
                      <i className="fas fa-chevron-down"></i>
                    </span>
                  </div>
                  <div className="accordion-content">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="section cta-section" ref={sectionRefs.current[4]}>
          <div className="container section-content">
            <div className="cta-content">
              <h2 className="cta-title">Shop with Limitless</h2>
              <p className="cta-description">Instant Auto Replacement</p>
              <p className="cta-support">24/7 Customer Support</p>
              <Link 
                to="/shop" 
                className="cta-button" 
                replace
                onClick={prefetchShopData}
              >
                Shop Now <i className="fas fa-arrow-right"></i>
              </Link>
            </div>

            <footer className="footer">
              <div className="footer-links">
                <a href="/terms" className="footer-link">Terms of Service</a>
                <a href="/privacy" className="footer-link">Privacy Policy</a>
                <a href="/support" className="footer-link">Support</a>
              </div>
              <p className="copyright">© 2024 Limitless</p>
            </footer>
          </div>
        </section>
      </div>

      {/* Custom dynamic styles */}
      <style jsx="true">
        {dynamicStyles}
      </style>

      {/* Main styles */}
      <style jsx="true">{`
        /* Global Styles */
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        .fullpage-container {
          position: relative;
          height: 100vh;
          overflow: hidden;
          background: transparent !important;
          padding-top: 48px;
        }

        .container {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          background: transparent !important;
        }

        /* Header Styles */
        .fixed-header {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1000;
          background: rgba(255,255,255,0.95); /* Add background to fixed header */
          backdrop-filter: blur(10px);
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }

        .main-nav {
          padding: 6px 0;
          height: 48px;
        }

        .nav-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
        }
        
        .brand-logo {
          font-size: 1.4rem;
          font-weight: 800;
          color: #4364F7;
          text-decoration: none;
          line-height: 1;
        }

        .nav-links {
          display: flex;
          gap: 24px;
          align-items: center;
        }

        .nav-link {
          color: #1a1a1a;
          text-decoration: none;
          font-weight: 600;
          font-size: 0.95rem;
          line-height: 1;
        }

        .login-button {
          background: #4364F7;
          color: #fff;
          padding: 8px 20px;
          border-radius: 25px;
          text-decoration: none;
          font-weight: 600;
          font-size: 0.95rem;
          line-height: 1;
          transition: all 0.3s ease;
          box-shadow: 0 4px 15px rgba(67,100,247,0.2);
        }

        .signup-button {
          background: #fff;
          color: #4364F7;
          padding: 8px 20px;
          border-radius: 25px;
          text-decoration: none;
          font-weight: 600;
          font-size: 0.95rem;
          line-height: 1;
          transition: all 0.3s ease;
          border: 1px solid #4364F7;
          margin-right: 10px;
        }

        .signup-button:hover {
          background: rgba(67,100,247,0.1);
        }

        .login-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 20px rgba(67,100,247,0.3);
        }

        /* Section Dots Navigation */
        .nav-dots {
          position: fixed;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          flex-direction: column;
          gap: 15px;
          z-index: 1000;
        }

        .nav-dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          /* Remove 3D effects */
          box-shadow: none;
        }

        .nav-dot:hover {
          background-color: rgba(255, 255, 255, 0.8);
          transform: scale(1.1);
        }

        .nav-dot.active {
          background-color: white;
          transform: scale(1.1);
          /* Remove 3D effects */
          box-shadow: none;
        }

        .nav-dot.active::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #FFC107; /* Yellow indicator */
          border-radius: 50%;
          /* Center properly */
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        /* Sections Container */
        .sections-container::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera */
        }
        
        /* Section styling with improved viewport alignment */
        .section {
          position: relative;
          min-height: 100vh;
          width: 100%;
          scroll-snap-align: start;
          overflow: hidden;
          box-sizing: border-box;
          isolation: isolate;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin: 0;
          z-index: 1;
          background: transparent !important;
        }

        /* Ensure section content is properly contained */
        .section-content {
          position: relative;
          z-index: 2;
          padding: 80px 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          max-height: calc(100vh - 100px);
          background: transparent !important;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .section-content::-webkit-scrollbar {
          display: none;
        }

        /* First section needs extra padding for header */
        .hero-section .section-content {
          padding-top: 120px;
          padding-bottom: 80px;
        }

        /* Remove all section-specific background gradients */
        .features-section,
        .payment-section,
        .faq-section,
        .cta-section {
          background: transparent !important;
        }

        /* Adjust the container for smooth scrolling */
        .sections-container {
          position: relative;
          height: calc(100vh - 48px); /* Adjust for fixed header height */
          overflow-y: auto;
          scroll-snap-type: y mandatory;
          scroll-behavior: smooth;
        }

        /* Add subtle overlay to help with text contrast */
        .section::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.05);
          pointer-events: none;
          z-index: 0;
        }

        /* Adjust FAQ section specific styles */
        .faq-section .section-content {
          padding-top: 80px;
          max-height: 100vh;
          overflow: visible;
        }

        .faq-container {
          flex: 1;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          padding: 20px 5px;
          margin: 0 auto;
          width: 100%;
          max-width: 800px;
          max-height: calc(100vh - 200px);
          position: relative;
          /* Add scroll padding to create the extra scroll needed to move to next section */
          scroll-padding-bottom: 50px;
          padding-bottom: 100px; /* Add bottom padding to require extra scroll */
          mask-image: linear-gradient(to bottom, 
            transparent 0%, 
            black 5%, 
            black 95%, 
            transparent 100%
          );
          -webkit-mask-image: linear-gradient(to bottom, 
            transparent 0%, 
            black 5%, 
            black 95%, 
            transparent 100%
          );
        }

        /* Ensure CTA section fills viewport */
        .cta-section {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 80px; /* Add padding to prevent OS content from being cut off by footer */
        }

        .cta-section .section-content {
          padding: 80px 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        /* Remove any margin/padding that might cause gaps */
        .section > *,
        .section-content > * {
          margin-left: 0;
          margin-right: 0;
        }

        /* Ensure proper stacking of sections */
        .hero-section { z-index: 5; }
        .features-section { z-index: 4; }
        .payment-section { z-index: 3; }
        .faq-section { z-index: 2; }
        .cta-section { z-index: 1; }

        /* Media query adjustments */
        @media (max-width: 768px) {
          .section-content {
            padding: 60px 15px;
          }

          .hero-section .section-content {
            padding-top: 120px /* Increase top padding on mobile */
          }
        }

        @media (max-height: 600px) {
          .section-content {
            padding: 40px 15px;
          }

          .hero-section .section-content {
            padding-top: 100px /* Adjust top padding for small height screens */
          }
        }

        /* Hero Section */
        .hero-section {
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          padding-top: 80px; /* Add top padding to create space for the fixed header */
        }
        
        @media (max-width: 768px) {
          .hero-section .section-content {
            padding-top: 120px /* Increase top padding on mobile */
          }
        }
        
        @media (max-height: 800px) {
          .hero-section .section-content {
            padding-top: 100px /* Adjust top padding for small height screens */
          }
        }

        .hero-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          max-width: 800px;
          margin: 20px auto 0; /* Add top margin to push content down */
          padding: 0 20px;
          background: transparent; /* Ensure transparent background */
        }

        .hero-subtitle {
          color: #fff;
          font-size: clamp(0.9rem, 2vw, 1.2rem);
          margin-top: 20px; /* Add top margin to push subtitle down from header */
          margin-bottom: 16px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.1);
          background: transparent; /* Ensure transparent background */
        }

        .hero-subtitle strong,
        .hero-subtitle b,
        .hero-subtitle #1 {
          color: rgba(255, 237, 179, 0.95); /* Soft gold - same as title-dot */
          font-weight: 700;
        }

        .hero-title {
          font-size: clamp(2rem, 6vw, 4rem);
          font-weight: 800;
          margin-bottom: 24px;
          color: #fff;
          text-shadow: 0 2px 10px rgba(0,0,0,0.15);
          letter-spacing: 1px;
          line-height: 1.2;
          background: transparent; /* Ensure transparent background */
        }

        .hero-description {
          font-size: clamp(1rem, 2vw, 1.2rem);
          line-height: 1.6;
          max-width: 800px;
          margin: 0 auto 32px;
          color: #fff;
          text-shadow: 0 1px 2px rgba(0,0,0,0.1);
          padding: 0 10px;
          background: transparent; /* Ensure transparent background */
        }

        .hero-buttons {
          display: flex;
          gap: clamp(8px, 2vw, 16px);
          justify-content: center;
          margin-bottom: 48px;
          flex-wrap: wrap;
          padding: 10px;
          background: transparent; /* Ensure transparent background */
        }

        .primary-button {
          background: #FFC107;
          color: #000;
          padding: clamp(10px, 2vw, 14px) clamp(20px, 4vw, 36px);
          border-radius: 25px;
          font-weight: 700;
          font-size: 1.1rem;
          display: inline-flex;
          align-items: center;
          gap: 8px;
          text-decoration: none;
          transition: transform 0.2s, background 0.3s ease; /* Smoother transitions */
          box-shadow: 0 4px 15px rgba(0,0,0,0.2);
          white-space: nowrap;
        }

        .primary-button:hover {
          transform: translateY(-3px);
        }

        .secondary-button {
          background: rgba(255,255,255,0.1); /* More subtle background */
          color: #fff;
          padding: 14px 36px;
          border-radius: 25px;
          font-weight: 600;
          font-size: 1.1rem;
          text-decoration: none;
          transition: background 0.3s ease; /* Smoother transition */
          backdrop-filter: blur(5px); /* Reduced blur */
          border: 1px solid rgba(255,255,255,0.1);
        }

        .secondary-button:hover {
          background: rgba(255,255,255,0.15);
        }

        .hero-stats {
          background: rgba(255,255,255,0.05);
          backdrop-filter: blur(8px);
          border: 1px solid rgba(255,255,255,0.1);
          border-radius: 12px;
          box-shadow: 0 10px 30px rgba(0,0,0,0.1);
          margin-top: auto;
          width: 65%;
          max-width: 600px;
          align-self: center;
          position: relative;
          z-index: 2;
          margin-bottom: min(20px, 2vh);
          transition: all 0.4s ease;
          padding: 12px 10px;
          transform: translateY(0);
        }

        .hero-stats:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 40px rgba(0,0,0,0.15);
          border-color: rgba(255,255,255,0.15);
          background: rgba(255,255,255,0.08);
        }

        .stats-list {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: clamp(8px, 2vw, 20px);
          list-style: none;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
        }

        .stat-item {
          text-align: center;
          min-width: max-content;
          padding: 0 clamp(6px, 1.2vw, 15px);
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .stat-icon {
          font-size: 22px;
          color: white;
          background: rgba(255, 255, 255, 0.08);
          width: 45px;
          height: 45px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          transition: all 0.3s ease;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
        }

        .stat-item:nth-child(1) .stat-icon {
          background: linear-gradient(135deg, rgba(255, 193, 7, 0.3), rgba(255, 152, 0, 0.3));
        }

        .stat-item:nth-child(3) .stat-icon {
          background: linear-gradient(135deg, rgba(76, 175, 80, 0.3), rgba(56, 142, 60, 0.3));
        }

        .stat-item:nth-child(5) .stat-icon {
          background: linear-gradient(135deg, rgba(79, 195, 247, 0.3), rgba(3, 169, 244, 0.3));
        }

        .hero-stats:hover .stat-icon {
          transform: scale(1.05);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        }

        .stat-number {
          font-size: clamp(1.2rem, 3vw, 2rem);
          font-weight: 700;
          margin-bottom: min(4px, 0.8vh);
          background: linear-gradient(135deg, #ffffff, rgba(255, 255, 255, 0.8));
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          white-space: nowrap;
          line-height: 1.1;
          letter-spacing: 0.5px;
          position: relative;
        }

        .stat-number::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 40%;
          height: 2px;
          background: linear-gradient(90deg, 
            rgba(255, 255, 255, 0), 
            rgba(255, 255, 255, 0.8), 
            rgba(255, 255, 255, 0));
        }

        .stat-label {
          font-size: clamp(0.6rem, 1vw, 0.75rem);
          color: rgba(255, 255, 255, 0.8);
          font-weight: 600;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .stat-divider {
          width: 1px;
          height: 30px;
          background: linear-gradient(to bottom, 
            rgba(255, 255, 255, 0), 
            rgba(255, 255, 255, 0.4), 
            rgba(255, 255, 255, 0));
          align-self: center;
        }

        @media (min-width: 768px) {
          .stat-divider {
            display: block;
          }
        }

        /* Features Section */
        .features-section {
          position: relative;
          z-index: 1;
          background: linear-gradient(to bottom, #6FB1FC 0%, #00C6FB 100%);
          overflow: hidden;
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px;
          margin: 0 auto;
          max-width: 1100px;
          padding: 0 20px;
          transition: opacity 0.5s ease, transform 0.5s ease;
          position: relative;
          will-change: opacity, transform;
          transform-origin: center center;
        }

        .features-grid.transitioning {
          opacity: 0;
          transform: scale(0.98);
        }

        @keyframes fadeInRight {
          from {
            opacity: 0;
            transform: translateX(30px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes fadeInLeft {
          from {
            opacity: 0;
            transform: translateX(-30px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @media (max-width: 768px) {
          .features-grid {
            grid-template-columns: 1fr;
          }
        }

        .features-nav-dots {
          display: flex;
          justify-content: center;
          gap: 15px;
          margin: 30px 0 10px;
        }

        .feature-nav-dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          box-shadow: none;
          padding: 0;
        }

        .feature-nav-dot:hover {
          background-color: rgba(255, 255, 255, 0.8);
          transform: scale(1.1);
        }

        .feature-nav-dot.active {
          background-color: white;
          transform: scale(1.1);
        }

        .feature-nav-dot.active::before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: #4364F7;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .feature-card {
          background: rgba(255,255,255,0.03) !important;
          backdrop-filter: blur(3px);
          border: 1px solid rgba(255,255,255,0.03) !important;
          box-shadow: 0 4px 20px rgba(0,0,0,0.03) !important;
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.5s ease;
          padding: 25px 20px;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: auto;
          min-height: 200px;
          animation: fadeIn 0.5s ease-out;
          opacity: 0;
          animation-fill-mode: forwards;
        }

        .feature-card:nth-child(1) {
          animation-name: fadeInLeft;
          animation-delay: 0.1s;
        }

        .feature-card:nth-child(2) {
          animation-name: fadeInRight;
          animation-delay: 0.3s;
        }

        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .feature-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 30px rgba(0,0,0,0.15);
        }

        .feature-icon {
          font-size: 28px;
          color: rgba(255, 255, 255, 0.9);
          background: rgba(255, 255, 255, 0.1);
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          transition: all 0.3s ease;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
        }

        .feature-card:hover .feature-icon {
          transform: scale(1.1);
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
          background: rgba(255, 255, 255, 0.15);
        }

        .feature-title {
          color: white;
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 15px;
          line-height: 1.3;
        }

        .feature-description {
          color: rgba(255, 255, 255, 0.9);
          font-size: 0.95rem;
          line-height: 1.5;
        }

        /* Global section header styles - center aligned */
        .section-header {
          margin-bottom: 40px;
          text-align: center; /* Center align all section headers */
          width: 100%;
        }
          
        .section-tag {
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 10px;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: #FFC107;
          text-align: center; /* Ensure tag is centered */
        }
          
        .section-title {
          font-size: clamp(2rem, 4vw, 3rem);
          margin-bottom: 30px;
          font-weight: 700;
          color: #fff;
          text-shadow: 0 2px 4px rgba(0,0,0,0.1);
          text-align: center; /* Ensure title is centered */
        }

        /* Fix specific section styles that might override this */
        .features-section .section-header,
        .payment-section .section-header,
        .faq-section .section-header {
          text-align: center;
        }

        .features-section .section-title,
        .payment-section .section-title,
        .faq-section .section-title {
          text-align: center;
        }

        /* Payment Section */
        .payment-section {
          background: linear-gradient(to bottom, #00C6FB 0%, #4364F7 100%);
          position: relative;
        }

        .payment-section .section-content {
          padding-bottom: calc(50px + 5vh); /* Adaptive padding based on viewport height */
        }

        .payment-methods {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 30px;
          margin-top: 40px;
          margin-bottom: calc(30px + 4vh); /* Adaptive margin */
          padding-bottom: calc(20px + 3vh); /* Adaptive padding */
        }

        .payment-method {
          background: rgba(255,255,255,0.05);
          backdrop-filter: blur(10px);
          border: 1px solid rgba(255,255,255,0.1);
          box-shadow: 0 10px 25px rgba(0,0,0,0.1);
          border-radius: 20px;
          padding: 30px 20px;
          width: 180px;
          text-align: center;
          transition: all 0.35s ease;
          transform-origin: center bottom;
          position: relative;
          overflow: hidden;
        }
        
        .payment-method::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background: rgba(255,255,255,0.2);
          transform: scaleX(0);
          transition: transform 0.35s ease;
        }
        
        .payment-method:hover::after {
          transform: scaleX(1);
        }

        .payment-method:hover {
          transform: translateY(-8px);
          box-shadow: 0 15px 35px rgba(0,0,0,0.15);
        }

        .payment-icon {
          font-size: 2.5rem;
          background: rgba(255, 255, 255, 0.1);
          width: 85px;
          height: 85px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 20px;
          transition: all 0.3s ease;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
          color: rgba(255, 255, 255, 0.9);
          position: relative;
          overflow: hidden;
        }

        .payment-icon:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
          z-index: 0;
        }

        .payment-icon i {
          position: relative;
          z-index: 1;
        }

        /* Custom colors for each payment method */
        .payment-method:nth-child(1) .payment-icon {
          background: linear-gradient(135deg, #F7931A, #cd7c14); /* Bitcoin */
        }

        .payment-method:nth-child(2) .payment-icon {
          background: linear-gradient(135deg, #62688F, #454a63); /* Ethereum */
        }

        .payment-method:nth-child(3) .payment-icon {
          background: linear-gradient(135deg, #345D9D, #284980); /* Litecoin */
        }

        .payment-method:nth-child(4) .payment-icon {
          background: linear-gradient(135deg, #0070BA, #00559e); /* PayPal */
        }
        
        .payment-method:hover .payment-icon {
          transform: scale(1.1);
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
        }

        .payment-name {
          color: #fff;
          font-size: 1.1rem;
          font-weight: 700;
          letter-spacing: 0.5px;
          margin-top: 8px;
          position: relative;
          transition: all 0.3s ease;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        .payment-method:hover .payment-name {
          transform: translateY(-2px);
        }
        
        /* Add subtle animation for the payment methods */
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-5px); }
        }
        
        .payment-method:nth-child(1) { animation: float 6s ease-in-out infinite; }
        .payment-method:nth-child(2) { animation: float 6.5s ease-in-out infinite 0.5s; }
        .payment-method:nth-child(3) { animation: float 7s ease-in-out infinite 1s; }
        .payment-method:nth-child(4) { animation: float 6.8s ease-in-out infinite 1.5s; }

        /* FAQ Section */
        .faq-section {
          background: linear-gradient(to bottom, #4364F7 0%, #6FB1FC 100%);
          position: relative;
          margin-bottom: 0;
          z-index: 2;
        }

        .faq-section .section-content {
          padding-top: calc(60px + min(30px, 3vh));
          padding-bottom: calc(70px + min(50px, 5vh));
          display: flex;
          flex-direction: column;
          overflow: hidden;
          /* Enhanced masking to prevent content overflow */
          mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 60px, rgba(0,0,0,1) calc(100% - 80px), rgba(0,0,0,0) 100%);
          -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 60px, rgba(0,0,0,1) calc(100% - 80px), rgba(0,0,0,0) 100%);
        }

        .faq-section .section-header {
          position: sticky;
          top: 0;
          padding: 20px 0;
          margin-bottom: min(30px, 3vh);
          /* Remove clashing gradient and replace with subtle transparent background */
          background: rgba(67, 100, 247, 0.85);
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          z-index: 10;
          border-radius: 0 0 30px 30px;
          text-align: center;
        }

        .faq-container {
          flex: 1;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          padding: 0 5px calc(40px + min(50px, 5vh)) 5px;
          margin: 0 auto;
          width: 100%;
          max-width: 800px;
          box-sizing: border-box;
          position: relative;
          /* Better fade out effect at top and bottom */
          mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
          -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
          /* Add some transform to create a new stacking context */
          transform: translateZ(0);
        }

        .accordion-item {
          border-radius: 12px;
          background: rgba(255,255,255,0.03);
          margin-bottom: 15px;
          overflow: hidden;
          border: 1px solid rgba(255,255,255,0.05);
          transition: all 0.3s ease;
        }

        .accordion-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          cursor: pointer;
          user-select: none;
        }

        .accordion-header h3 {
          font-size: 1.1rem;
          color: #fff;
          margin: 0;
          font-weight: 600;
          flex: 1;
        }

        .accordion-icon {
          color: #FFC107;
          transition: transform 0.3s ease;
        }

        .active-accordion-item .accordion-icon {
          transform: rotate(180deg);
        }

        .accordion-content {
          padding: 0 20px;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease, padding 0.3s ease;
          background: rgba(0,0,0,0.1);
        }

        .active-accordion-item .accordion-content {
          padding: 0 20px 20px 20px;
          max-height: 300px; /* Allow more content to be visible */
          overflow-y: auto; /* Make long content scrollable */
        }

        .accordion-content p {
          margin: 0;
          color: rgba(255,255,255,0.9);
          line-height: 1.6;
          font-size: 1rem;
        }

        /* CTA Section */
        .cta-section {
          position: relative;
          z-index: 1;
          box-shadow: none;
          margin-top: 0;
          background: transparent !important;
        }

        .cta-section .section-content {
          padding-top: calc(90px + min(50px, 5vh));
          background: transparent !important;
        }

        .cta-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: min(40px, 4vh);
          background: transparent !important;
        }

        .cta-title {
          font-size: clamp(2.5rem, 5vw, 3.5rem);
          font-weight: 800;
          color: #fff;
          margin-bottom: 20px;
          text-shadow: 0 2px 10px rgba(0,0,0,0.15);
        }

        .cta-description {
          font-size: 1.5rem;
          color: #fff;
          margin-bottom: 10px;
        }

        .cta-support {
          font-size: 1.2rem;
          color: rgba(255,255,255,0.9);
          margin-bottom: 40px;
        }

        .cta-button {
          background: #FFC107;
          color: #000;
          padding: 15px 40px;
          border-radius: 30px;
          font-weight: 700;
          font-size: 1.2rem;
          display: inline-flex;
          align-items: center;
          gap: 10px;
          text-decoration: none;
          transition: transform 0.3s, box-shadow 0.3s;
          box-shadow: 0 5px 20px rgba(0,0,0,0.2);
          margin-bottom: 60px;
        }

        .cta-button:hover {
          transform: translateY(-3px);
          box-shadow: 0 8px 25px rgba(0,0,0,0.3);
        }

        /* Footer */
        .footer {
          width: 100%;
          padding: 20px 0;
          text-align: center;
          margin-top: 40px;
          position: relative; /* Change from absolute to relative positioning */
          bottom: auto; /* Remove absolute positioning */
          z-index: 1;
        }

        .footer-links {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-bottom: 10px;
          flex-wrap: wrap;
        }

        .footer-link {
          color: rgba(255, 255, 255, 0.7);
          text-decoration: none;
          font-size: 0.9rem;
          transition: color 0.2s;
        }

        .footer-link:hover {
          color: #fff;
        }

        .copyright {
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.8rem;
        }

        /* Media Queries */
        @media (min-width: 768px) {
          .stat-divider {
            display: block;
          }
        }

        @media (min-height: 1000px) {
          .section {
            padding-top: calc(8vh);
            padding-bottom: calc(8vh);
          }
        }

        /* Ensure CTA section has proper spacing */
        .cta-section {
          margin-bottom: 0 !important; /* Last section doesn't need bottom margin */
          padding-bottom: 0 !important; /* Last section doesn't need bottom padding */
        }

        /* Special handling for last accordion items */
        .last-items .accordion-content {
          max-height: 200px !important; /* More restrictive for last items */
        }

        /* Make hero section adjust content based on viewport height */
        @media (max-height: 800px) {
          .hero-subtitle {
            margin-bottom: 8px;
          }
          
          .hero-title {
            font-size: clamp(1.8rem, 5vw, 3.5rem);
            margin-bottom: 16px;
          }
          
          .hero-description {
            font-size: clamp(0.9rem, 1.8vw, 1.1rem);
            margin-bottom: 20px;
            max-height: 25vh;
            overflow-y: auto;
          }
          
          .hero-buttons {
            margin-bottom: 20px;
          }
          
          .primary-button, .secondary-button {
            padding: 8px 20px;
            font-size: 0.95rem;
          }
        }

        /* Adaptive styles for different screen heights */
        @media (max-height: 800px) {
          .section-content {
            padding-top: calc(60px + min(20px, 2vh));
            padding-bottom: calc(15px + min(20px, 2vh));
          }
          
          .faq-section .section-content {
            padding-top: calc(40px + min(20px, 2vh));
            padding-bottom: calc(40px + min(30px, 3vh));
          }
          
          .cta-section .section-content {
            padding-top: calc(60px + min(30px, 3vh));
          }
          
          .faq-container {
            padding-bottom: calc(20px + min(30px, 3vh));
          }
          
          .section-header {
            margin-bottom: 20px;
          }
          
          .section-tag {
            font-size: 0.9rem;
            margin-bottom: 5px;
          }
          
          .section-title {
            font-size: clamp(2rem, 4vw, 2.8rem);
            margin-bottom: 20px;
          }
          
          .cta-title {
            font-size: clamp(2rem, 4vw, 3rem);
            margin-bottom: 15px;
          }
          
          .cta-description, .cta-support {
            margin-bottom: 8px;
          }
          
          .cta-button {
            margin-bottom: 30px;
          }
        }

        /* Make sure all content elements in CTA are transparent */
        .cta-title, .cta-description, .cta-support {
          background: transparent !important;
        }

        .footer-link, .copyright {
          background: transparent !important;
        }

        /* Specific fix for FAQ to CTA transition */
        @keyframes faqToCta {
          0% {
            background: linear-gradient(to bottom, #00C6FB 0%, #A85CE6 100%);
          }
          100% {
            background: linear-gradient(to bottom, #A85CE6 0%, #F5426C 100%);
          }
        }
        
        /* When FAQ and CTA sections are both partially visible, add extra transition time */
        .faq-section, .cta-section {
          transition: all 0.6s ease-out !important;
        }

        /* Override overflow for the features section specifically */
        .features-section .section-content {
          overflow-y: visible;
          max-height: none;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        /* Responsive adaptations for the features section */
        @media (max-width: 768px) {
          .features-section {
            min-height: auto;
            height: auto;
          }
          
          .features-section .section-content {
            padding: 60px 20px;
          }
          
          .feature-card {
            min-height: 180px;
            padding: 20px 15px;
          }
        }

        /* Update the FAQ section CSS to ensure proper scrolling */
        .faq-section .section-content {
          overflow-y: auto;
          max-height: calc(100vh - 120px);
          padding-right: 20px; /* Add some padding for the scrollbar */
          scrollbar-width: thin;
          scrollbar-color: rgba(255,255,255,0.2) transparent;
        }

        .faq-section .section-content::-webkit-scrollbar {
          width: 8px;
          display: block;
        }

        .faq-section .section-content::-webkit-scrollbar-track {
          background: rgba(0,0,0,0.05);
          border-radius: 10px;
        }

        .faq-section .section-content::-webkit-scrollbar-thumb {
          background: rgba(255,255,255,0.2);
          border-radius: 10px;
          border: 2px solid transparent;
          background-clip: padding-box;
        }

        .faq-section .section-content::-webkit-scrollbar-thumb:hover {
          background: rgba(255,255,255,0.3);
          border-radius: 10px;
          border: 2px solid transparent;
          background-clip: padding-box;
        }

        /* Custom styling for Litecoin icon */
        .custom-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
        
        .litecoin-icon svg {
          width: 60%;
          height: 60%;
        }
        
        /* Ensure the Litecoin icon has the right color */
        .payment-method:nth-child(3) .payment-icon .litecoin-icon svg {
          fill: #fff;
        }
        
        /* Custom styling for the payment icon to ensure it works with both Font Awesome and custom SVG */
        .payment-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 3rem;
          margin-bottom: 15px;
          background: rgba(255, 255, 255, 0.1);
          width: 85px;
          height: 85px;
          border-radius: 50%;
          margin: 0 auto 15px;
          color: #fff;
          transition: all 0.35s ease;
        }

        /* Text accent styling for hero section */
        .text-accent-1 {
          color: #FFC107; /* Gold/yellow color for #1 to emphasize leadership */
          font-weight: 700;
          text-shadow: 0 2px 5px rgba(255, 193, 7, 0.3);
        }
        
        .text-accent-2 {
          color: #00BCD4; /* Cyan/blue for PREMIUM to emphasize quality */
          font-weight: 700;
          text-shadow: 0 2px 5px rgba(0, 188, 212, 0.3);
        }
        
        .text-accent-3 {
          color: #4CAF50; /* Green for PRODUCTS to emphasize value */
          font-weight: 700;
          text-shadow: 0 2px 5px rgba(76, 175, 80, 0.3);
        }
        
        .text-highlight-1 {
          color: #8BC34A; /* Light green for ethical/experience emphasis */
          font-weight: 600;
          text-shadow: 0 1px 3px rgba(139, 195, 74, 0.2);
        }
        
        .text-highlight-2 {
          color: #FFD54F; /* Amber for numerical values and calls to action */
          font-weight: 600;
          text-shadow: 0 1px 3px rgba(255, 213, 79, 0.2);
        }
        
        .text-highlight-3 {
          color: #4FC3F7; /* Light blue for service-related terms */
          font-weight: 600;
          text-shadow: 0 1px 3px rgba(79, 195, 247, 0.2);
        }
        
        /* Animation for the title */
        @keyframes shimmer {
          0% {
            background-position: -100% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
        
        .text-gradient {
          background: linear-gradient(135deg, #FF5722, #FF9800, #FFC107, #FF9800, #FF5722);
          background-size: 200% 100%;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          font-weight: 900;
          filter: drop-shadow(0 2px 4px rgba(255, 87, 34, 0.2));
          animation: shimmer 6s infinite linear;
        }
        
        .text-dot {
          color: #FFC107; /* Gold/yellow for dot */
          font-weight: 900;
          font-size: 110%;
        }
        
        .text-store {
          color: #E0E0E0; /* Light gray for STORE - more subtle */
          font-weight: 800;
          letter-spacing: 2px;
          text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
        
        /* Animation for the title */
        @keyframes shimmer {
          0% {
            background-position: -100% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
        
        /* More subtle styling for hero section text */
        .subtle-accent {
          color: rgba(255, 255, 255, 0.85);
          letter-spacing: 1.5px;
          font-weight: 600;
          position: relative;
          padding-bottom: 2px;
        }
        
        .subtle-accent::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, 
            rgba(255, 255, 255, 0), 
            rgba(255, 255, 255, 0.7), 
            rgba(255, 255, 255, 0));
        }
        
        .title-main {
          position: relative;
          z-index: 2;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 1) 15%,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 0.7) 35%,
            rgba(255, 255, 255, 0.6) 50%,
            rgba(255, 255, 255, 0.7) 65%,
            rgba(255, 255, 255, 0.7) 100%
          );
          background-size: 300% 100%;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          font-weight: 800;
          letter-spacing: 1px;
          animation: shimmerText 4s ease-in-out infinite;
          text-shadow: 0 0 3px rgba(255, 255, 255, 0.2);
          filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.2)) contrast(1.1);
        }
        
        /* Title container and streaming service icons */
        .title-main-container {
          position: relative;
          display: inline-block;
          overflow: visible;
        }
        
        /* Create animation for floating icons */
        @keyframes floatIconsAnimation {
          0% { opacity: 0.6; transform: translateY(0) rotate(0deg) scale(1); }
          50% { opacity: 1; transform: translateY(-5px) rotate(5deg) scale(1.1); }
          100% { opacity: 0.6; transform: translateY(0) rotate(0deg) scale(1); }
        }
        
        .shimmer-icon {
          position: absolute;
          color: rgba(255, 255, 255, 0.5);
          animation: floatIconsAnimation 4s ease-in-out infinite;
          pointer-events: none;
          z-index: 1;
        }

        @keyframes shimmerText {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        
        .title-dot {
          color: rgba(255, 237, 179, 0.95); /* Soft gold */
          font-weight: 700;
        }
        
        .subtle-highlight {
          color: rgba(255, 237, 179, 0.9); /* Soft gold */
          font-weight: 500;
          font-style: italic;
        }
        
        .hero-title {
          font-size: clamp(2rem, 6vw, 4rem);
          font-weight: 700;
          margin-bottom: 24px;
          color: #fff;
          text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
          letter-spacing: 1px;
          line-height: 1.2;
        }
        
        .text-dot {
          color: #FFC107; /* Gold/yellow for dot */
          font-weight: 900;
          font-size: 110%;
        }
        
        .text-store {
          color: #E0E0E0; /* Light gray for STORE - more subtle */
          font-weight: 800;
          letter-spacing: 2px;
          text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }

        /* Mouse Trail Effect Styles */
        .mouse-trail-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          pointer-events: none;
          z-index: 9999;
          overflow: hidden;
        }
        
        .mouse-trail-particle {
          position: absolute;
          transform-origin: center center;
          pointer-events: none;
          filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.15);
          overflow: visible;
          z-index: 9999;
          box-shadow: 0 0 6px 0px currentColor;
        }
        
        .mouse-trail-particle svg {
          stroke: currentColor;
          stroke-width: 1.5px;
          width: 85%;
          height: 85%;
        }
        
        /* Improve performance */
        .mouse-trail-container {
          pointer-events: none;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9999;
          overflow: hidden;
          will-change: transform;
        }
      `}</style>
    </div>
  );
};

export default Home;

