import Loader from "./Loader";
export default function PageLoader() {
  return (
    <div className="loader text-center" style={{ 
      padding: '2rem', 
      minHeight: '300px', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      width: '100%'
    }}>
      <div>
        <Loader className="spinner-border-lg" />
        <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>Loading Products...</p>
      </div>
    </div>
  );
}
