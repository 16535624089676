import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import Requests from "../utils/requests";
const requests = new Requests();

class Footer extends PureComponent {
  state = {
    links: [],
  };

  componentDidMount = async () => {
    this.setState({
      links: await requests.getSocialMediaLinks(),
    });
  };

  render() {
    const { links } = this.state;
    const { embedded } = this.props;
    const footerStyle = embedded
      ? {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          scrollSnapAlign: "none",
        }
      : {
          position: "relative",
          backgroundColor: "#1a2942",
          width: "100%",
          bottom: 0,
          left: 0,
          right: 0
        };
    return (
      <footer className="footer_section" id={embedded ? "embedded-footer" : "footer"} style={footerStyle}>
        <div className="container py-3">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6">
              <div className="d-flex align-items-center gap-2">
                <Link to="/">
                  <img
                    src="/assets/images/logo-ftr.svg"
                    alt="Logo"
                    className="img-fluid"
                    style={{ height: "30px" }}
                  />
                </Link>
                <span style={{ color: 'rgba(255,255,255,0.7)', fontSize: '0.85rem' }}>
                  &copy; 2024 Limitless.store
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="d-flex justify-content-center gap-4" style={{ fontSize: '0.9rem' }}>
                <Link to="/shop" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Shop</Link>
                <Link to="/feedback" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Feedback</Link>
                <Link to="/redeem-key" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Redeem Key</Link>
                <Link to="/find-order" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Find Order</Link>
                <Link to="/terms-of-service" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Terms</Link>
                <Link to="/privacy-policy" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Privacy</Link>
                {!this.props.loggedIn && (
                  <>
                    <Link to="/login" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Sign In</Link>
                    <Link to="/signup" style={{ color: 'rgba(255,255,255,0.8)', textDecoration: 'none' }}>Create Account</Link>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-flex justify-content-end gap-3">
                {links.map((link, index) => (
                  <a
                    key={index}
                    href={link.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'rgba(255,255,255,0.8)', fontSize: '1.1rem' }}
                  >
                    <i className={`fa-brands fa-${link.name}`}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
